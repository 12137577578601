
import React from "react";
import {
    Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space,
    message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Result,
} from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';


class MyOpenMenu extends React.Component {
    constructor(props) {
        super(props);
        //默认了第一个类目，把第二个类目提取出来
        var category1ActiveData = { n: "all", c: [] }
        var category2 = []
        if (props.defaultCategory1) {
            category1ActiveData = props.defaultCategory1
            props.categoryData.map(item => {
                if (category1ActiveData.n == item.n) {
                    category2 = item.c
                }
            })
        }

        this.state = {
            paramsParams: {},//分页查询的参数缓存

            clickFun: props.clickFun,
            category1_hide: props.category1_hide ? props.category1_hide : false,
            category2_hide: props.category2_hide ? props.category2_hide : false,
            category3_hide: props.category3_hide ? props.category3_hide : false,
            category1: props.categoryData,
            category2: category2,
            category3: [],
            category1ActiveData: category1ActiveData,
            category2ActiveData: { n: "all", c: [] },
            category3ActiveData: { n: "all", c: [] },
        };
    }

    componentDidMount() {
        let _this = this;
    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //跳转到另一个路由
    jumpToNewHash = (hashPath) => {
        this.props.history.push(hashPath);//跳转到新的路由
    }

    //调用父级的监听方法
    parentsClickFun = () => {
        const { category1ActiveData, category2ActiveData, category3ActiveData } = this.state;
        var paramsData = {
            "category1Id": null,//一级菜单
            "category2Id": null,//二级菜单
            "category3Id": null,//三级菜单
        }
        if (category1ActiveData && category1ActiveData.n != "all") {
            paramsData["category1Id"] = category1ActiveData.i
        }
        if (category2ActiveData && category2ActiveData.n != "all") {
            paramsData["category2Id"] = category2ActiveData.i
        }
        if (category3ActiveData && category3ActiveData.n != "all") {
            paramsData["category3Id"] = category3ActiveData.i
        }


        this.state.clickFun(paramsData)
    }

    //一级分类按钮点击事件
    category1ClickFun = (item) => {
        this.setState({
            "category1ActiveData": item,
            "category2ActiveData": { n: "all", c: [] },
            "category3ActiveData": { n: "all", c: [] },
            "category2": item.c,
            "category3": [],
        }, function () { this.parentsClickFun() })

    }
    //二级分类按钮点击事件
    category2ClickFun = (item) => {
        this.setState({
            "category2ActiveData": item,
            "category3ActiveData": { n: "all", c: [] },
            "category3": item.c,
        }, function () { this.parentsClickFun() })
    }
    //三级分类按钮点击事件
    category3ClickFun = (item) => {
        this.setState({
            "category3ActiveData": item,
        }, function () { this.parentsClickFun() })
    }

    render() {
        const { expanded, category1, category2, category3, category1ActiveData, category2ActiveData, category3ActiveData,
            category1_hide,category2_hide,category3_hide,
         } = this.state;
        //判断是不是手机屏幕

        return (
            <React.Fragment>
                <div style={{ marginBottom: "20px" }}>
                    {
                        category1 && category1.length > 0 ?
                            <Row>
                                <Col xs={3} sm={3} md={3} lg={1} xl={1} xxl={1} style={{ minWidth: "58px" }}>
                                    <span className="openMenuTitle">一级类目</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={22} xl={22} xxl={22}>
                                    <Typography.Paragraph className="openMenuBox" ellipsis={{
                                        rows: 1,//默认展示1行
                                        expandable: 'collapsible',
                                        symbol: ((expanded) => expanded ? <span className="openMenuMore">收起<UpOutlined /></span> : <span className="openMenuMore">更多<DownOutlined /></span>),
                                        expanded,
                                        onExpand: (_, info) => this.setStateKeyValue("expanded", info.expanded),
                                    }}
                                    >
                                        <Button className={"all" == category1ActiveData.n ? "openMenuBtn openMenuBtnActive" : "openMenuBtn"} type="text" onClick={() => this.category1ClickFun({ n: "all", c: [] })}>全部</Button>
                                        {category1.map((item, index) => {
                                            return <Button className={item.n == category1ActiveData.n ? "openMenuBtn openMenuBtnActive" : "openMenuBtn"} type="text" onClick={() => this.category1ClickFun(item)}>{item.n} </Button>
                                        })}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>

                            : null
                    }

                    {
                        !category2_hide && category2 && category2.length > 0 ?

                            <Row>
                                <Col xs={3} sm={3} md={3} lg={1} xl={1} xxl={1} style={{ minWidth: "58px" }}>
                                    <span className="openMenuTitle">二级类目</span>
                                </Col>
                                <Col xs={18} sm={18} md={18} lg={22} xl={22} xxl={22}>
                                    <Typography.Paragraph className="openMenuBox" ellipsis={{
                                        rows: 1,//默认展示1行
                                        expandable: 'collapsible',
                                        symbol: ((expanded) => expanded ? <span className="openMenuMore">收起<UpOutlined /></span> : <span className="openMenuMore">更多<DownOutlined /></span>),
                                        expanded: this.state.expanded2,
                                        onExpand: (_, info) => this.setStateKeyValue("expanded2", info.expanded),
                                    }}
                                    >
                                        <Button className={"all" == category2ActiveData.n ? "openMenuBtn openMenuBtnActive" : "openMenuBtn"} type="text" onClick={() => this.category2ClickFun({ n: "all", c: [] })}>全部</Button>
                                        {category2.map((item, index) => {
                                            return <Button className={item.n == category2ActiveData.n ? "openMenuBtn openMenuBtnActive" : "openMenuBtn"} type="text" onClick={() => this.category2ClickFun(item)}>{item.n} </Button>
                                        })}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                            : null
                    }
                    {
                        !category3_hide &&  category3 && category3.length > 0 ?
                            <Row>
                                <Col xs={3} sm={3} md={3} lg={1} xl={1} xxl={1} style={{ minWidth: "58px" }}>
                                    <span className="openMenuTitle">三级类目</span>

                                </Col>
                                <Col xs={18} sm={18} md={18} lg={22} xl={22} xxl={22}>
                                    <Typography.Paragraph className="openMenuBox" ellipsis={{
                                        rows: 1,//默认展示1行
                                        expandable: 'collapsible',
                                        symbol: ((expanded) => expanded ? <span className="openMenuMore">收起<UpOutlined /></span> : <span className="openMenuMore">更多<DownOutlined /></span>),
                                        expanded: this.state.expanded3,
                                        onExpand: (_, info) => this.setStateKeyValue("expanded3", info.expanded),
                                    }}
                                    >
                                        <Button className={"all" == category3ActiveData.n ? "openMenuBtn openMenuBtnActive" : "openMenuBtn"} type="text" onClick={() => this.category3ClickFun({ n: "all", c: [] })}>全部</Button>
                                        {category3.map((item, index) => {
                                            return <Button className={item.n == category3ActiveData.n ? "openMenuBtn openMenuBtnActive" : "openMenuBtn"} type="text" onClick={() => this.category3ClickFun(item)}>{item.n} </Button>
                                        })}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                            : null
                    }
                </div>


            </React.Fragment>
        );
    }
}

export default withRouter(MyOpenMenu);