import React from "react";
import { searchNews, newsChangeType, changeHot, changeSort } from "../http/api";
import CommonUtils from '../http/Common';
import {
  Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space,
  message, Popconfirm, Badge, Typography, notification, Tooltip, Image, Spin, Tag, Switch,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "./SearchItem.js";
import dayjs from 'dayjs';


class NewsManagerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      paramsParams: {},//分页查询的参数缓存
      spinning: false,//是否为加载中状态
      showModel_: false,
      tableData: [],
      tableData_total: 0,
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部
    //获取缓存的用户信息
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    this.setState({
      "user": user_cache,
    })

    this.searchFun({})

  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //跳转到另一个路由
  jumpToNewHash = (hashPath) => {
    this.props.history.push(hashPath);//跳转到新的路由
  }

  searchFunPageFun = (current, pageSize) => {
    var paramsParams = this.state.paramsParams
    if(current && pageSize){
      paramsParams["current"] = current
      paramsParams["size"] = pageSize
    }
    this.searchFun(paramsParams) //查询代理人拆分的代金券
  }

  searchFun = (params) => {
    let _this = this;
    if (!params) {
      params = {}
    }
    //缓存参数，分页时候使用
    this.setState({
      "paramsParams": params,
      spinning: true,
    })

    searchNews(params).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          tableData: resData.records,
          tableData_total: resData.total,
        })
        message.success("请求成功！")
      } else {
        _this.setState({
          tableData: [],
          tableData_total: 0,
        })
      }
    }).catch(function (error) {//请求失败时
      message.info("创建失败，请重试！店铺名称不能有标点符号")
    })
  }

  changeTypeFun = (id, type) => {
    let _this = this;
    newsChangeType({ id, type }).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
        })
        message.success("请求成功！")
      } else {
        _this.setState({
        })
      }
      _this.searchFunPageFun()//刷新数据
    }).catch(function (error) {//请求失败时
      message.info("请求失败，请重试！")
    })
  }

  changeHotFun = (id, hot) => {
    let _this = this;
    changeHot({ id, hot }).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
        })
        message.success("请求成功！")
      } else {
        _this.setState({
        })
      }
      _this.searchFunPageFun()//刷新数据
    }).catch(function (error) {//请求失败时
      message.info("请求失败，请重试！")
    })
  }

  changeSortFun = (id, sort) => {
    let _this = this;
    _this.setState({ spinning: true })
    changeSort({ id, sort }).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
        })
        notification.success({
          "key":new Date().getTime(),
          "message":"请求成功！",
          "description":'筛选条件"是否热门" 选项选择热门才会生效。 客户界面不影响。',
        })
      } else {
        _this.setState({
        })
        notification.warning({
          "key":new Date().getTime(),
          "message":"已经置顶 ！",
          "description":'该文章已经置顶，请勿重复操作！',
        })
      }
      _this.searchFunPageFun()//刷新数据
    }).catch(function (error) {//请求失败时
      message.info("请求失败，请重试！")
    })
  }

  render() {
    const { spinning, showModel_, tableData, tableData_total, user } = this.state;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()
    const searchItems = [
      {
        "title": "文章标签",
        "key": "tag",
        "type": "select",
        "options": [{ label: "全部", value: "" },
        { label: "行业资讯", value: 1 },
        { label: "运营技巧", value: 2 },
        { label: "基础常识", value: 3 },
        { label: "国家政策", value: 4 },
        ]
      },
      {
        "title": "状态",
        "key": "type",
        "type": "select",
        "options": [{ label: "全部", value: "" },
        { label: "草稿", value: 0 },
        { label: "审核中", value: 1 },
        { label: "已发布", value: 2 },
        ]
      },
      {
        "title": "是否热门",
        "key": "hot",
        "type": "select",
        "options": [{ label: "全部", value: "" },
        { label: "热门", value: 1 },
        { label: "不热门", value: 0 },
        ]
      },
      // {
      //   "title": "关键词",
      //   "key": "title",
      //   "type": "input",
      //   "placeholder": "标题",
      // },
    ]

    var columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        // render: (text) => <a>{text}</a>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '文章标题',
        dataIndex: 'title',
        key: 'title',
        width:200,
        ellipsis: true,
      },
      {
        title: '文章标签',
        dataIndex: 'tag',
        key: 'tag',
        render: (text) => {//1:行业资讯  2:运营技巧   3:基础常识   4:国家政策
          if (text == 1) {
            return <a>行业资讯</a>
          } else if (text == 2) {
            return <a>运营技巧</a>
          } else if (text == 3) {
            return <a>基础常识</a>
          } else if (text == 4) {
            return <a>国家政策</a>
          }
        }
      },
      {
        title: '状态',
        dataIndex: 'type',
        key: 'type',
        //状态  0-草稿  1-审核中  2-已经发布
        render: (text) => {
          if (text == 0) {
            return <Badge count={"草稿"} style={{ backgroundColor: '#d3d3d3', }} />
          }
          if (text == 1) {
            return <Badge count={"审核中"} style={{ backgroundColor: '#FF803A', }} />
          }
          if (text == 2) {
            return <Badge count={"已发布"} style={{ backgroundColor: '#52c41a', }} />
          }
        }
      },
      {
        title: '是否热门',
        dataIndex: 'hot',
        key: 'hot',
        render: (_, record) => {
          return <Switch key={new Date().getTime()} checkedChildren="已热门" unCheckedChildren="非热门"
            defaultChecked={record.hot}
            disabled={record.type != 2 ? true : false}
            onChange={(checked) => this.changeHotFun(record.id, checked)} />
        },
      },
      {
        title: '排序',
        dataIndex: 'sort',
        key: 'sort',
        render: (_, record) => {
          if(record.type == 2 && record.hot){
            return <>
            <svg onClick={() => this.changeSortFun(record.id,1)} t="1728443962789" className="cursorPointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5727" width="16" height="16"><path d="M536.567 105.568l0.492 0.525 265.885 289.451c15.303 16.66 14.204 42.57-2.456 57.874-16.493 15.15-42.054 14.224-57.412-1.96l-0.462-0.496-193.75-210.923v652.889c0 22.622-18.338 40.96-40.96 40.96s-40.96-18.338-40.96-40.96V238.307L273.277 450.422c-15.1 16.539-40.643 17.858-57.362 3.083l-0.504-0.452c-16.54-15.1-17.859-40.644-3.084-57.363l0.453-0.504 263.864-289.001c16.047-17.576 43.593-17.793 59.922-0.617z" fill="#8a8a8a" p-id="5728"></path></svg>
            <svg onClick={() => this.changeSortFun(record.id,0)} t="1728444146413" className="cursorPointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10147" width="16" height="16"><path d="M542.293333 268.373333a42.666667 42.666667 0 0 0-14.08-8.96 42.666667 42.666667 0 0 0-32.426666 0 42.666667 42.666667 0 0 0-14.08 8.96l-170.666667 170.666667a42.666667 42.666667 0 1 0 60.586667 60.586667L469.333333 401.493333V896a42.666667 42.666667 0 0 0 85.333334 0V401.493333l97.706666 98.133334a42.666667 42.666667 0 0 0 60.586667 0 42.666667 42.666667 0 0 0 0-60.586667zM810.666667 85.333333H213.333333a42.666667 42.666667 0 0 0 0 85.333334h597.333334a42.666667 42.666667 0 0 0 0-85.333334z" p-id="10148" fill="#8a8a8a"></path></svg>
          </>
          }
          
        },
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '发布时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '操作',
        key: 'action',
        width: "188px",//只有文字才有用
        render: (_, record) => {
          // console.log(record)
          return <Space size="small">
            {/* // 1： 超级管理员  2：普通管理员  else： 客户 */}
            {/* type;//状态  0-草稿  1-审核中  2-已经发布 */}
            <a className="colCardActionBtn" onClick={() => window.open(window.location.origin+"/newsDetail?number=" +record.id, "_blank")}>预览</a>
            {
              record.type == 0 ? <a className="colCardActionBtn" onClick={() => this.changeTypeFun(record.id, 1)}>提交</a> : null
            }
            {
              record.type == 1 && user.attr4 == "1" ? <a className="colCardActionBtn" onClick={() => this.changeTypeFun(record.id, 2)}>发布</a> : null
            }
            <a className="colCardActionBtn" onClick={() => this.changeTypeFun(record.id, 0)}>下架</a>
            <a className="colCardActionBtn" onClick={() => this.changeTypeFun(record.id, -1)}>删除</a>
            {record.originalUrl && record.originalUrl != "无" ? <a href={record.originalUrl} target="_blank" className="colCardActionBtn" >原文</a> : null}
          </Space>
        },
      },
    ];


    return (
      <React.Fragment>
        <SearchItem searchFun={this.searchFun} searchItems={searchItems} />
        <Spin tip="查询中..." spinning={spinning}>
          <Table columns={columns} dataSource={tableData} size={isPhone ? "small" : "middle"}
            pagination={{
              position: ["bottomCenter"], showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
              total: tableData_total,
              showTotal: (total) => `共 ${total} 条`,
              onChange: this.searchFunPageFun
            }}
          />
        </Spin>

        <Modal title="添加xpath" open={showModel_} footer={null} onCancel={() => this.setStateKeyValue("showModel_", false)}>
          <Form labelCol={{ span: 5, }}
            wrapperCol={{ span: 19, }}
            style={{ maxWidth: 600, }}
            onFinish={this.addAuthShopFun}
            autoComplete="off"
          >
            <Form.Item label="店铺名称" name="shop_name"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              <Input placeholder="如：店铺1-ozon" />
            </Form.Item>

            <Form.Item label="Client ID" name="shopId"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              <Input type="number" />
            </Form.Item>
            <Form.Item label="API 密钥" name="accessToken"
              rules={[{
                required: true,
                message: '请输入店铺名称!',
              },]} >
              {/* <Input.Password /> */}
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
              <Button type="primary" htmlType="submit">
                添加授权
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(NewsManagerPage);