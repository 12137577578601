
import React from "react";
import {
    Dropdown, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space,
    message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Switch,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import CommonUtils from '../../http/Common';

//封装了Table：增加了用户可选择展示的列，并缓存使用习惯

class MyTable extends React.Component {
    constructor(props) {
        super(props);
        var showKeyListCache = CommonUtils.getLocalStorage(props.tableName)//获取缓存的showKeyList

        this.state = {
            paramsParams: {},//分页查询的参数缓存
            spinning: false,//是否为加载中状态
            tableName: props.tableName,//用来获取缓存的showKeyList
            showKeyList: showKeyListCache ? showKeyListCache : (props.showKeyList ? props.showKeyList : []),
            allColumns: props.allColumns,
            tableData: [],
        };
    }

    componentDidMount() {
        let _this = this;
    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //跳转到另一个路由
    jumpToNewHash = (hashPath) => {
        this.props.history.push(hashPath);//跳转到新的路由
    }

    handleOpenChange = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            this.setStateKeyValue("tableDropdownOpen", nextOpen);
        }
    };

    //展示哪些列的撤回按钮
    tableSettingBackFun = () => {
        const { tableName } = this.state;
        var showKeyList = this.props.showKeyList ? this.props.showKeyList : []
        this.setState({
            showKeyList: showKeyList
        })
        //缓存起来，记录用户使用记录
        CommonUtils.setLocalStorage(tableName, showKeyList)
    }

    //switch的变化事件
    switchOnChangeFun = (key) => {
        var { showKeyList, allColumns, tableName } = this.state;
        //判断是否所有key都显示了
        var isALlShow = true
        allColumns.map(item => {
            if (!showKeyList.includes(item.key)) {
                isALlShow = false
            }
        })

        if (key == "all") {
            showKeyList = []
            if (!isALlShow) {//全选
                allColumns.map(item => {
                    showKeyList.push(item.key)
                })
                showKeyList.push('all')
            }
        } else {
            if (showKeyList.includes(key)) {
                showKeyList.splice(showKeyList.indexOf(key), 1)//数组删除指定元素
            } else {
                showKeyList.push(key)
            }

            //判断是否所有key都显示了
            var isALlShow = true
            allColumns.map(item => {
                if (!showKeyList.includes(item.key)) {
                    isALlShow = false
                }
            })

            if (isALlShow) {
                showKeyList.push("all")
            } else if (showKeyList.includes("all")) {
                showKeyList.splice(showKeyList.indexOf(key), 1)//数组删除指定元素
            }

        }

        this.setState({
            showKeyList: showKeyList
        })

        if (showKeyList && showKeyList.length > 0) {
            //缓存起来，记录用户使用记录
            CommonUtils.setLocalStorage(tableName, showKeyList)
        }

    }


    // var element = { shopId: "shopId", shopName: "shopName", area: "area", shop_cbsc: "shop_cbsc", authType: "authType", authTime: "authTime", isVipActive: "isVipActive", }
    // const elementsArray = Array(100).fill(element); // 使用 map 方法构造一个新数组，包含 count 个 element 副本

    render() {
        const { allColumns, showKeyList, tableData, tableDropdownOpen } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()

        var items = [
            {
                label: <div style={{ lineHeight: "25px" }}>
                    <span>表格设置</span>
                    <Tooltip title="显示默认展示列。"><div className="table_setting_back" onClick={this.tableSettingBackFun}>
                        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7097" width="16" height="16"><path d="M454.1 251.1V129.5L129.4 314.6l324.7 197.7V384.8c255.1 0.2 313 127.6 313 187.5 0 67.5-57.9 194.8-313 194.8H256.9v127.4h197.2c313 0 440.6-127.3 440.6-316.9-0.1-192.6-127.6-326.5-440.6-326.5z" p-id="7098" fill="#005bff"></path></svg>
                    </div>
                    </Tooltip>



                </div>,
                disabled: true,
                className: "table_setting_text"
            },
            {
                type: 'divider',
            },
            {
                label: <>
                    <Switch defaultChecked={showKeyList.includes("all")} onChange={() => this.switchOnChangeFun("all")} />
                    <span className="table_setting_text_item">全选</span>
                </>,
                key: showKeyList,
            }
        ];

        var showColumns = []
        allColumns.map((item, index) => {
            items.push({
                label: <>
                    <Switch defaultChecked={showKeyList.includes(item.key) ? true : false} onChange={() => this.switchOnChangeFun(item.key)} />
                    <span className="table_setting_text_item">{item.title}</span>
                </>,
                key: showKeyList,
            })

            if (showKeyList.includes(item.key)) {
                showColumns.push(item)
            }
        })

        return (
            <React.Fragment>
                {
                    !isPhone ?
                        <div className="table_icon_setting_box">
                            <div className="table_icon_setting">
                                <Dropdown open={tableDropdownOpen} menu={{ items: items, className: "table_setting" }} trigger={['click']} onOpenChange={this.handleOpenChange} >
                                    <svg className="cursorPointer" viewBox="0 0 1024 1024" version="1.1" width="22" height="22" style={{ color: "#005bff" }}><path d="M512 691.433A180.13 180.13 0 1 0 332.8 512 179.665 179.665 0 0 0 512 691.433zM93.09 512a471.04 471.04 0 0 1 8.38-88.67 157.09 157.09 0 0 0 120.552-81.22 170.59 170.59 0 0 0 6.05-153.135A414.487 414.487 0 0 1 385.165 93.09a153.135 153.135 0 0 0 258.094 0 415.65 415.65 0 0 1 156.393 97.047 169.658 169.658 0 0 0 7.447 151.971 156.393 156.393 0 0 0 116.364 78.895 461.964 461.964 0 0 1-3.258 193.163 158.487 158.487 0 0 0-112.64 80.757 169.658 169.658 0 0 0-11.637 139.636 416.116 416.116 0 0 1-162.21 96.35 152.67 152.67 0 0 0-241.339 0 412.16 412.16 0 0 1-159.65-94.954 170.124 170.124 0 0 0-10.706-142.196 157.324 157.324 0 0 0-116.364-81.222A461.265 461.265 0 0 1 93.091 512z" p-id="3851" fill="#005bff"></path></svg>
                                </Dropdown>
                            </div>
                        </div>
                        : null
                }


                <Table columns={showColumns} size={isPhone ? "small" : "middle"}  {...this.props} {...this.extraProps} />

            </React.Fragment>
        );
    }
}

export default withRouter(MyTable);