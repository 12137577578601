import React from "react";
import { getSumOfCoupon, getAgentData, getLimitMoney, getCategoryMini } from "../http/api";
import CommonUtils from '../http/Common';
import { Layout, Menu, Col, Row, Image, Button, Card, message, Tooltip, Badge, notification } from 'antd';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
const { Header, Content, Sider } = Layout;


var categoryMiniData = CommonUtils.getLocalStorage("categoryMiniData")


class StatisticsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      couponTotal_noUse: 0,
      couponTotal_used: 0,
      baseCount: 0,
      recommendCount: 0,
      shopCount: 0,
      earnMoney: 0,//总收益
      needCashOut: 0,//待提现
      checkMoney: 0,//审核中
      hadCashOut: 0,//已提现
      lastUpdateTime: "",//已提现
      couponMoney_limit: 0,
    };
  }

  componentDidMount() {
    let _this = this;
    //CommonUtils.goToTop()//回到顶部

    //不存在获取
    if (!categoryMiniData) {
      this.getCategoryMiniFun(); //获取最小化ozon 类型数据
    }
  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }


  //获取最小化ozon 类型数据  categoryMiniData
  getCategoryMiniFun = () => {
    let _this = this;
    getCategoryMini({}).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        CommonUtils.setLocalStorage("categoryMiniData", resData)
        //message.success("请求成功！")
      } else {
        _this.setState({
          categoryData: [],
        })
      }
    }).catch(function (error) {//请求失败时
      message.info("请求失败，请重试！")
    })
  }


  render() {
    const { couponTotal_noUse, couponTotal_used, baseCount, recommendCount, shopCount, earnMoney, needCashOut, hadCashOut, checkMoney, lastUpdateTime,
      couponMoney_limit,
    } = this.state;
    var menu_items = CommonUtils.UI_Get_menu_items_Statistics()

    var selectedKeys = ""
    var defaultOpenKeys = null
    menu_items.map(i => {
      if (window.location.pathname.includes(i.myPath)) {
        selectedKeys = i.key
      } else if (i.children) {
        i.children.map(j => {
          if (window.location.pathname.includes(j.myPath)) {
            defaultOpenKeys = i.key
            selectedKeys = j.key
          }
        })
      }
    })

    var user = CommonUtils.getLocalStorage("user");//缓存用户信息

    // console.log("selectedKeys==>",selectedKeys)

    // //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    var menu_way = CommonUtils.get_menu_way(window.location.pathname)
    // console.log("menu_way==>", menu_way)

    return (
      <React.Fragment>
        <div className="statisticsPageBox">



          {/* 放总览信息  */}

          <Layout style={{ background: "none" }}>
            {
              isPhone ? null :
                <Sider width={200} >
                  <Menu
                    mode="inline"
                    // mode="vertical"
                    selectedKeys={[selectedKeys]}
                    // defaultOpenKeys={defaultOpenKeys ? [defaultOpenKeys] : ["Ozon统计", "Ozon选品"]}//默认打开的父菜单
                    defaultOpenKeys={["Ozon统计", "Ozon选品"]}//默认打开的父菜单
                    style={{
                      height: '100%',
                      minHeight: '100vh',
                      borderRight: "#f1f0f0 solid 1px !important"
                    }}
                    items={menu_items}
                  />
                </Sider>
            }


            <Layout style={isPhone ? { padding: '22px', background: "#FFF", marginLeft: "0px" } : { padding: '22px', background: "#FFF", marginLeft: "28px" }} >
              <Content className="itemMain">
                {this.props.children}
              </Content>
            </Layout>
          </Layout>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(StatisticsPage);