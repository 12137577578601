import React from "react";
import { buyingVip, makeGroupBuying, getGroupBuying, buyTools, getSumOfCoupon, getShopMinPrice, buyPackage,getPrice, } from "../http/api";
import CommonUtils from '../http/Common';
import ReactWebsocket from './components/ReactWebsocket.js';
import {
   Image, Radio, InputNumber, Input, Col, Row, Button, Table, Checkbox, Avatar, Divider,
   Tooltip, Tag, QRCode, Statistic, message, Modal, Result, Typography, notification,
   Tabs, Card, Form, Popconfirm, Drawer,
} from 'antd';
import {
   CheckCircleOutlined, CloseCircleOutlined, CopyOutlined, QrcodeOutlined, QuestionCircleOutlined,
   RightOutlined, InfoCircleOutlined,
} from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import MyImageBanner from "./components/MyImageBanner.js";
import WechatJsCommon from './components/WechatJsCommon';


const { Countdown } = Statistic;
const { Text } = Typography;



class Price extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         buyShopType: "buyShopCount", //订购方式
         buyPlatform: "shopCommon", //订购平台  通用平台： shopCommon ; shopOzon shopTiktok
         shopCount: 1, //店铺数量
         vipTime: 1, //订购周期 默认一个月
         originalPrice: null,//原价
         payMoney_noCoupon: null,//应付价 -不用代金券
         payablePrice: null,//应付价
         payByCoupon: 2,//代金券抵扣的额度
         fans: false,//关注大卖粉丝
         translateImg: false,//加一元送图片翻译
         finalTime: null,//优惠券倒计时
         payQrCodeUrl: null,//微信支付的二维码链接

         openWebsocket_group: false,//拼团websocket
         showModal_payGroup: false,//拼团支付
         showModal: false,//
         showModal_payQrCode: false,//
         showModal_payTools: false,//
         showBuyDrawer: false,//
         isCouponBuy: false,//是否是团购
         showModal_buygroup_info: false,//团购信息展示
         payTitle: "",//扫码支付的title
         websocket_pay: false,//打开支付回调的websoccket
         new100Phone: [],//团购
         resourcePackageCheckValue: [],//资源包选项
         expireStamp: null,
         groupDiscount: 9.5, //直接打九折
         groupCode: null,
         groupDiscountPrice: 0,//团购价

         imageTranslateCount: 1000,//图片翻译次数
         textTranslateCount: 1000000,//文本翻译次数
         fansCount: 1,//单买-关注粉丝店铺次数
         fansTime: 1,//

         analysis_data_ozon_Count: 1,//分析数据
         analysis_data_ozon_Time:1,
         analysis_data_ozon_price:18,
         analysis_data_ozon_oldPrice:36,

         TabsActiveKey: '1',// tab的活动key
         isPhone: CommonUtils.isWeiXin() ? false : CommonUtils.isPhoneClient(),//只用在支付选择上
         isWechat: CommonUtils.isWeiXin(),
         couponTotal_noUse: 0,//可用代金券的总额
         basePrice: 10,//单个店铺单个月的价格
         shopMinPrice: 8,//一个店铺一个月最少的收入

      };
   }

   componentDidMount() {
      let _this = this;
      CommonUtils.goToTop()//回到顶部


      //拼团码链接  -- 先取消拼团
      // var groupCode = CommonUtils.GetQueryString("gc") + ""
      // if (groupCode && groupCode.length == 8) {
      //    this.groupBuyingCodeFun(groupCode) // 查询拼团码
      // }

      this.getShopMinPriceFun(); //获取一个店铺一个月最低价格

      var isWeiXinLogin = CommonUtils.isWeiXinLogin() //判断是不是微信登录
      if (isWeiXinLogin) {
         setTimeout(() => {
            _this.initFun();//初始化方法
         }, 3000);
      } else {
         _this.initFun();//初始化方法
      }

   }

   //初始化方法
   initFun = () => {
      let _this = this;
      WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 
      this.getSumOfCouponFun(); //查询代金券总和

   }


   setShopCountFun = (value) => {
      this.setState({
         shopCount: value
      }, this.countPrice)

   }

   setVipTimeFun = (value) => {
      this.setState({
         vipTime: value
      }, this.countPrice)
   }

   shopCountInputFun = (value) => {
      console.log(value)
      this.setState({
         shopCount: parseInt(value) ? parseInt(value) : 1
      }, this.countPrice)
   }

   //由后端计算价格
   getPriceFun = (key,params)=>{
      let _this = this;
      getPrice(params).then(res=>{
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            // console.log(resData)
            _this.setState({
               [key+"_price"]: resData.price,
               [key+"_oldPrice"]: resData.oldPrice,
            })
         }else{

         }
      })
   }


   //计算价格
   countPrice = () => {
      const { shopCount, vipTime, fans, translateImg, couponTotal_noUse, shopMinPrice, basePrice } = this.state;
      var discountNum = 1; //订购周期折扣
      switch (vipTime) {
         case 3:
            discountNum = 0.98;
            break;
         case 6:
            discountNum = 0.88;
            break;
         case 12:
            discountNum = 0.78;
            break;
         // case 36:
         //    discountNum = 0.68;
         //    break;
      }

      //一个店铺一个月能优惠的钱：
      var coupon_one_shop_month = basePrice - shopMinPrice / discountNum;
      if (shopCount * vipTime < 5) {
         coupon_one_shop_month = 2
      }
      var coupon_all_shop_month = coupon_one_shop_month * shopCount * vipTime

      //判断总共不能超过代金券的总额
      if (coupon_all_shop_month > couponTotal_noUse) {
         coupon_all_shop_month = couponTotal_noUse
      }

      var originalPrice = basePrice * shopCount * vipTime * 2;

      var payMoney_noCoupon = basePrice * shopCount * vipTime * discountNum; //单独购买- 不用代金券
      var endPayMoney = (basePrice * shopCount * vipTime - coupon_all_shop_month) * discountNum; //最终支付   //减去优惠券可以抵消的钱


      // console.log("一个店铺一个月能优惠的钱：", coupon_one_shop_month, " 总共优惠：", coupon_all_shop_month)


      //关注粉丝
      if (fans) {
         originalPrice += shopCount
         payMoney_noCoupon += shopCount
         endPayMoney += shopCount
      }

      //加一元送500张图片翻译
      if (translateImg) {
         originalPrice += shopCount
         payMoney_noCoupon += shopCount
         endPayMoney += shopCount
      }




      if (endPayMoney < 1) {
         endPayMoney = 1
         message.info("最低1元!")
      }

      // var groupDiscount_Temp = groupDiscount ? groupDiscount : 9.5
      // var groupDiscountPrice = 0;
      // if (groupDiscount_Temp) {//拼团
      //    groupDiscountPrice = endPayMoney * groupDiscount_Temp / 10;
      //    if (groupDiscountPrice < 1) {
      //       groupDiscountPrice = 1
      //       message.info("最低1元!")
      //    }
      // }

      // console.log("平均每个店铺的价格：", endPayMoney / shopCount / vipTime)

      this.setState({
         originalPrice: parseInt(originalPrice),//原价
         payMoney_noCoupon: parseInt(payMoney_noCoupon),//应付价
         payablePrice: parseInt(endPayMoney),//应付价
         payByCoupon: parseInt(coupon_all_shop_month),//代金券抵扣的额度
      })

   }

   //立即支付按钮
   shopBuyingFun = (isCouponBuy) => {
      let _this = this;
      const { shopCount, vipTime, fans, translateImg, payablePrice, isPhone, payMoney_noCoupon, buyPlatform, } = this.state;
      if (!CommonUtils.hasLogin()) { return };//检测是否登录

      var type = "shop";//"shop+fans+translateImg";
      if (fans) {
         type += "+fans"
      }
      if (translateImg) {
         type += "+translateImg"
      }
      var isWeiXin = CommonUtils.isWeiXin()
      var params = {
         buyCount: shopCount,//购买数量
         buyTime: vipTime,//购买时间  单位是：月
         type: type,//支付类型（用于购买什么的）  shop fans  translate
         noCouponPrice: payMoney_noCoupon,//上传前端的最终付款，用于核对计算值
         payablePrice: payablePrice,//代金券抵扣购买价
         couponBuy: isCouponBuy,//是否是代金券抵扣购买
         h5: isPhone,//是否是H5唤起手机支付
         "wechat": isWeiXin,//是否是微信浏览器唤起手机支付
         shopType: buyPlatform,//店铺类型   通用平台：shopCommon ; shopOzon  shopTiktok
      }

      // console.log("参数: ", params)


      buyingVip(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)
            _this.setState({
               websocket_pay: true,
            })

            if (isWeiXin) {
               WechatJsCommon.jsApiPay(resData.appId, resData.timeStamp, resData.nonceStr, resData.package, resData.paySign)
            } else if (isPhone) {//唤起手机支付
               window.open(resData)
            } else {//扫码支付
               var payTitle = isCouponBuy ? payablePrice : payMoney_noCoupon
               payTitle = "￥" + payTitle
               _this.setState({
                  payTitle: payTitle,
                  payQrCodeUrl: resData,
                  openWebsocket_group: false,
                  showModal_payQrCode: true,
                  websocket_pay: true,
               })
            }

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               openWebsocket_group: false,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      });
   }

   //按照套餐购买
   buyPackageFun = (vipType) => {
      let _this = this;
      //VIP 类型  vip1  vip2 vip3 vip4
      if (!CommonUtils.hasLogin()) {
         console.log("未登录!")
         return
      };//检测是否登录
      //判断是不是手机屏幕
      var isPhone = CommonUtils.isPhoneClient()
      var isWeiXin = CommonUtils.isWeiXin()

      var params = {
         "type": vipType,
         h5: isPhone,//是否是H5唤起手机支付
         "wechat": isWeiXin,//是否是微信浏览器唤起手机支付
      }
      buyPackage(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)
            _this.setState({
               websocket_pay: true,
            })
            if (isWeiXin) {
               WechatJsCommon.jsApiPay(resData.appId, resData.timeStamp, resData.nonceStr, resData.package, resData.paySign)
            } else if (isPhone) {//唤起手机支付
               window.open(resData)
            } else {//扫码支付
               _this.setState({
                  payTitle: "",
                  payQrCodeUrl: resData,
                  openWebsocket_group: false,
                  showModal_payQrCode: true,
                  websocket_pay: true,
               })
            }

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               openWebsocket_group: false,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      })
   }

   //订购ozon数据统计
   buyStatisticsOzonFun = () =>{
      const { isPhone,analysis_data_ozon_Count,analysis_data_ozon_Time,analysis_data_ozon_price } = this.state;
      let _this = this;
      this.setStateKeyValue("showModal_analysis_data", false)

      var isWeiXin = CommonUtils.isWeiXin()
      var params = {
         "type": "statisticsOzon",
         "buyCount": analysis_data_ozon_Count,
         "buyTime": analysis_data_ozon_Time,
         "payablePrice": analysis_data_ozon_price,
         "h5": isPhone,//是否是H5唤起手机支付
         "wechat": isWeiXin,//是否是微信浏览器唤起手机支付
      }

      buyTools(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)
            _this.setState({
               websocket_pay: true,
            })

            if (isWeiXin) {
               WechatJsCommon.jsApiPay(resData.appId, resData.timeStamp, resData.nonceStr, resData.package, resData.paySign)
            } else if (isPhone) {//唤起手机支付
               window.open(resData)
            } else {//扫码支付
               _this.setState({
                  payTitle: "订购统计选品",
                  payQrCodeUrl: resData,
                  showModal_payQrCode: true,
                  websocket_pay: true,
               })
            }

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      })
   }


   //订购图片翻译
   buyTranslateImage = () => {
      const { isPhone } = this.state;
      let _this = this;
      this.setStateKeyValue("showModal_translate", false)
      const { imageTranslateCount } = this.state;
      console.log("imageTranslateCount ::: ", imageTranslateCount)

      var isWeiXin = CommonUtils.isWeiXin()
      var params = {
         "type": "translateImg",
         "buyCount": imageTranslateCount,
         "payablePrice": 0.01 * imageTranslateCount,
         "h5": isPhone,//是否是H5唤起手机支付
         "wechat": isWeiXin,//是否是微信浏览器唤起手机支付
      }

      buyTools(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)
            _this.setState({
               websocket_pay: true,
            })

            if (isWeiXin) {
               WechatJsCommon.jsApiPay(resData.appId, resData.timeStamp, resData.nonceStr, resData.package, resData.paySign)
            } else if (isPhone) {//唤起手机支付
               window.open(resData)
            } else {//扫码支付
               _this.setState({
                  payTitle: "订购图片翻译",
                  payQrCodeUrl: resData,
                  showModal_payQrCode: true,
                  websocket_pay: true,
               })
            }

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      })

   }

   //订购文本翻译
   buyTranslateImageText = () => {
      const { isPhone } = this.state;
      let _this = this;
      this.setStateKeyValue("showModal_translate_text", false)
      const { textTranslateCount } = this.state;
      console.log("textTranslateCount ::: ", textTranslateCount)

      var isWeiXin = CommonUtils.isWeiXin()
      var params = {
         "type": "translateText",
         "buyCount": textTranslateCount,
         "payablePrice": 0.00004 * textTranslateCount,
         "h5": isPhone,//是否是H5唤起手机支付
         "wechat": isWeiXin,//是否是微信浏览器唤起手机支付
      }

      buyTools(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)
            _this.setState({
               websocket_pay: true,
            })

            if (isWeiXin) {
               WechatJsCommon.jsApiPay(resData.appId, resData.timeStamp, resData.nonceStr, resData.package, resData.paySign)
            } else if (isPhone) {//唤起手机支付
               window.open(resData)
            } else {//扫码支付
               _this.setState({
                  payTitle: "订购文本翻译",
                  payQrCodeUrl: resData,
                  showModal_payQrCode: true,
                  websocket_pay: true,
               })
            }

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      })

   }

   //订购关注大卖粉丝
   buyfans = () => {
      const { isPhone } = this.state;
      let _this = this;
      this.setStateKeyValue("showModal_follow_fans", false)
      const { fansCount, fansTime } = this.state;
      console.log(" fansCount,fansTime  ::: ", fansCount, fansTime)

      var isWeiXin = CommonUtils.isWeiXin()
      var params = {
         "type": "fans",
         "buyTime": fansTime,
         "buyCount": fansCount,
         "payablePrice": 5 * fansCount * fansTime,
         "h5": isPhone,//是否是H5唤起手机支付
         "wechat": isWeiXin,//是否是微信浏览器唤起手机支付
      }

      buyTools(params).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            console.log(resData)

            _this.setState({
               websocket_pay: true,
            })

            if (isWeiXin) {
               WechatJsCommon.jsApiPay(resData.appId, resData.timeStamp, resData.nonceStr, resData.package, resData.paySign)
            } else if (isPhone) {//唤起手机支付
               window.open(resData)
            } else {//扫码支付
               _this.setState({
                  payTitle: "订购关注大卖粉丝",
                  payQrCodeUrl: resData,
                  showModal_payQrCode: true,
                  websocket_pay: true,
               })
            }

         } else {
            _this.setState({
               payTitle: "",
               payQrCodeUrl: null,
               showModal_payQrCode: false,
               websocket_pay: false,
            })
         }
      })

   }

   //查询代金券总和 statusType  1-待使用/待赠送； 2-已使用
   getSumOfCouponFun = () => {
      let _this = this;
      var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
      if (!user_cache) { return }//未登录

      getSumOfCoupon({ statusType: 1 }).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            _this.setState({
               couponTotal_noUse: resData
            }, () => this.countPrice())
         } else {
            console.log("请求失败，请重试！")
            // message.info("请求失败，请重试！")
         }
      });
   }

   //获取一个店铺一个月最低价格
   getShopMinPriceFun = () => {
      const { buyPlatform } = this.state;
      let _this = this;

      getShopMinPrice({ shopType: buyPlatform }).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;
            _this.setState({
               shopMinPrice: resData.shopMinPrice,
               basePrice: resData.basePrice,
            }, () => this.countPrice()) //初始化价格
         } else {
            message.info("请求失败，请重试！")
         }
      });
   }

   setStateKeyValue = (key, value) => {
      this.setState({
         [key]: value
      })
   }

   //订购平台改变
   refreshPlatformFun = (buyPlatform) => {
      this.setState({
         buyPlatform: buyPlatform
      }, () => this.getShopMinPriceFun())

   }



   //打开支付界面
   setShowBuyDrawer = (isCouponBuy) => {
      this.setState({
         isCouponBuy: isCouponBuy,
         showBuyDrawer: true,
      })
   }

   onChangeCheckbox = (value) => {
      this.setState({
         fans: value.includes("fans"),
         translateImg: value.includes("translateImg"),
         resourcePackageCheckValue: value,
      }, this.countPrice)


   }

   scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

   //tab 被点击的回调
   onTabClickFun = (key, event) => {
      if (key == 1 || key == 2) {
         this.setState({
            TabsActiveKey: key
         })
      }
      else if (key == 3) {
         CommonUtils.scrollToElement("part-permissions")
      } else if (key == 4) {
         CommonUtils.scrollToElement("part-ordering-instructions")
      }
   }

   //发起拼团
   makeGroupBuyingFun = () => {
      let _this = this;
      if (!CommonUtils.hasLogin()) { return };//检测是否登录
      this.setState({
         openWebsocket_group: false,//先关闭，再次链接就是新的groupCode了
      })
      makeGroupBuying({}).then(res => {
         if (res && res.data && res.data.success) {
            let resData = res.data.data;

            _this.setState({
               new100Phone: resData.new100Phone,
               expireStamp: resData.expireStamp,
               groupDiscount: null,
               groupCodeInput: resData.groupCode,
               groupCode: resData.groupCode,
               openWebsocket_group: true,//websocket
            }, _this.countPrice)
            message.success("发起拼团成功！")

         } else {
            _this.setState({
               new100Phone: [],
               expireStamp: null,
               groupDiscount: null,
               groupCodeInput: null,
               groupCode: null,
               openWebsocket_group: false,//websocket
            }, _this.countPrice)
            message.error("每天最多发起10次团购，请明天再试！")
         }
      })
   }

   //团购码输入框
   groupBuyingCodeFun = (value) => {
      console.log(value)
      let _this = this;

      value = value ? value + "" : ""
      this.setState({
         new100Phone: [],
         expireStamp: null,
         groupDiscount: null,
         groupCode: null,
         payQrCodeUrl: null,
         groupCodeInput: value,
      }, _this.countPrice)

      if (value.length == 8) {
         if (!CommonUtils.hasLogin()) {
            this.setState({
               groupCodeInput: null
            })
            return
         };//检测是否登录
         getGroupBuying({ "groupCode": value }).then(res => {
            if (res && res.data && res.data.success) {
               let resData = res.data.data;
               console.log(resData)
               var groupDiscount = null;
               switch (resData.new100Phone.length) {
                  case 2:
                     groupDiscount = 9.5;
                     break
                  // case 3:
                  //    groupDiscount = 8;
                  //    break
                  // case 4:
                  //    groupDiscount = 7;
                  //    break
                  // case 5:
                  //    groupDiscount = 6;
                  //    break
                  default:
                     if (resData.new100Phone.length >= 3) {
                        groupDiscount = 8.8;
                     }
               }

               _this.setState({
                  new100Phone: resData.new100Phone,
                  expireStamp: resData.expireStamp,
                  groupDiscount: groupDiscount,
                  groupCode: value,
                  openWebsocket_group: true,//websocket
               }, _this.countPrice)
               message.success("拼团成功！")

            } else {
               _this.setState({
                  new100Phone: [],
                  expireStamp: null,
                  groupDiscount: null,
                  groupCode: null,
                  openWebsocket_group: false,//websocket
               }, _this.countPrice)
               message.error("团购号码已经过期，请重新发起拼团！")
            }
         });
      }

   }

   //websocket 事件
   onOpen = () => {
      console.log("onOpen")
   }

   //websocket 事件   SUCCESS == 支付成功
   onMessage = (msg) => {
      // console.log("onMessage", msg)
      try {
         msg = JSON.parse(msg)
         if (msg && msg.result && msg.result == "SUCCESS") {
            this.setState({
               payQrCodeUrl: null,//关闭支付二维码
               payTitle: "",
               showModal: true,
               showModal_payQrCode: false,
               websocket_pay: false,
            })

            //缓存权限
            CommonUtils.setLocalStorage("roleData", msg.roleData)
         }
      } catch (e) { }
   }

   //websocket ---团购参团提醒
   onMessageGroup = (msg) => {
      // console.log("onMessage", msg)
      try {
         msg = JSON.parse(msg)
         if (msg && msg.result && msg.result == "SUCCESS") {
            var groupDiscount = null;
            switch (msg.new100Phone.length) {
               case 2:
                  groupDiscount = 9.5;
                  break
               // case 3:
               //    groupDiscount = 8;
               //    break
               // case 4:
               //    groupDiscount = 7;
               //    break
               // case 5:
               //    groupDiscount = 6;
               //    break
               default:
                  if (msg.new100Phone.length >= 3) {
                     groupDiscount = 8.8;
                  }
            }

            this.setState({
               new100Phone: msg.new100Phone,
               expireStamp: msg.expireStamp,
               groupDiscount: groupDiscount,
            }, this.countPrice)
            message.success("有新朋友加入组团！")
         }
      } catch (e) { }
   }

   //websocket 事件
   onClose = () => {
      console.log("onClose")
   }

   //订购统计选品价格选中修改事件
   analysis_data_ozonChangeFun = (key,value) =>{
      let _this = this;
      var {analysis_data_ozon_Count,analysis_data_ozon_Time} = this.state;
      if(key == "analysis_data_ozon_Count"){
         analysis_data_ozon_Count = value
      }else if(key == "analysis_data_ozon_Time"){
         analysis_data_ozon_Time = value
      }

      var params = {
         "type":"statisticsOzon",
         "buyCount": analysis_data_ozon_Count,
         "buyTime":analysis_data_ozon_Time,
      }
      this.setState({
         analysis_data_ozon_Count:analysis_data_ozon_Count,
         analysis_data_ozon_Time:analysis_data_ozon_Time,
      })

      this.getPriceFun("analysis_data_ozon",params)
   }


   render() {
      const { shopCount, vipTime, payablePrice, originalPrice,
         payQrCodeUrl, showModal, new100Phone, expireStamp, groupDiscount, groupCode, groupCodeInput,
         groupDiscountPrice, openWebsocket_group, showModal_payQrCode, websocket_pay,
         showModal_translate, showModal_translate_text, imageTranslateCount, textTranslateCount, showModal_follow_fans,
         fansCount, fansTime, payTitle, TabsActiveKey, showBuyDrawer, isCouponBuy, showModal_buygroup_info, resourcePackageCheckValue,
         isWechat, couponTotal_noUse, payMoney_noCoupon, payByCoupon, buyPlatform, buyShopType, showModal_analysis_data, analysis_data_ozon_price,analysis_data_ozon_oldPrice,

      } = this.state;

      //判断是不是手机屏幕
      var isPhone = CommonUtils.isPhoneClient()

      const columns = [
         {
            title: '功能',
            // align: 'center',
            dataIndex: 'function',
            key: 'function',
            //   render: (text) => <a>{text}</a>,
         },
         {
            title: '免费版(永久)',
            // align: 'center',
            dataIndex: 'free',
            key: 'free',
            render: (text) => {
               if (typeof (text) == "string") {
                  return text
               } else if (text) {//true
                  return <CheckCircleOutlined style={{ fontSize: '20px', color: '#03b3b2' }} />
               } else {
                  return <CloseCircleOutlined style={{ fontSize: '20px', color: '#f56c6c' }} />
               }
            },
         },
         {
            title: '按店付费版',
            // align: 'center',
            dataIndex: 'pay',
            key: 'pay',
            render: (text) => {
               if (typeof (text) == "string") {
                  return text
               } else if (text) {//true
                  return <CheckCircleOutlined style={{ fontSize: '20px', color: '#03b3b2' }} />
               } else {
                  return <CloseCircleOutlined style={{ fontSize: '20px', color: '#f56c6c' }} />
               }
            },
         },
      ];
      const columns_package = [
         {
            title: '通用权益',
            // align: 'center',
            dataIndex: 'function',
            key: 'function',
            width: isPhone ? 100 : 200,
            // ellipsis: true,
            //   render: (text) => <a>{text}</a>,
         },
         {
            title: '',
            align: 'center',
            dataIndex: 'free',
            key: 'free',
            ellipsis: true,
            render: (text) => {
               if (typeof (text) == "string") {
                  return text
               } else if (text) {//true
                  return <CheckCircleOutlined style={{ fontSize: '20px', color: '#03b3b2' }} />
               } else {
                  return <CloseCircleOutlined style={{ fontSize: '20px', color: '#f56c6c' }} />
               }
            },
         },
         {
            title: '',
            align: 'center',
            dataIndex: 'vip1',
            key: 'vip1',
            ellipsis: true,
            render: (text) => {
               if (typeof (text) == "string") {
                  return text
               } else if (text) {//true
                  return <CheckCircleOutlined style={{ fontSize: '20px', color: '#03b3b2' }} />
               } else {
                  return <CloseCircleOutlined style={{ fontSize: '20px', color: '#f56c6c' }} />
               }
            },
         },
         {
            title: '',
            align: 'center',
            dataIndex: 'vip2',
            key: 'vip2',
            ellipsis: true,
            render: (text) => {
               if (typeof (text) == "string") {
                  return text
               } else if (text) {//true
                  return <CheckCircleOutlined style={{ fontSize: '20px', color: '#03b3b2' }} />
               } else {
                  return <CloseCircleOutlined style={{ fontSize: '20px', color: '#f56c6c' }} />
               }
            },
         },
         {
            title: '',
            align: 'center',
            dataIndex: 'vip3',
            key: 'vip3',
            ellipsis: true,
            render: (text) => {
               if (typeof (text) == "string") {
                  return text
               } else if (text) {//true
                  return <CheckCircleOutlined style={{ fontSize: '20px', color: '#03b3b2' }} />
               } else {
                  return <CloseCircleOutlined style={{ fontSize: '20px', color: '#f56c6c' }} />
               }
            },
         },
         {
            title: '',
            align: 'center',
            dataIndex: 'vip4',
            key: 'vip4',
            ellipsis: true,
            render: (text) => {
               if (typeof (text) == "string") {
                  return text
               } else if (text) {//true
                  return <CheckCircleOutlined style={{ fontSize: '20px', color: '#03b3b2' }} />
               } else {
                  return <CloseCircleOutlined style={{ fontSize: '20px', color: '#f56c6c' }} />
               }
            },
         },
      ];

      var data = [
         {
            key: '1',
            function: '可授权店铺数',
            free: "2个/平台",
            pay: '按订购数量',
         },
         {
            key: '2',
            function: '图片空间',
            free: "不限",
            pay: '不限',
         },
         {
            key: '3',
            function: '产品采集',
            free: "可采10个/天",
            pay: '店铺数*3000个/月',
         },
         {
            key: '4',
            function: '产品发布',
            free: true,
            pay: true,
         },
         {
            key: '5',
            function: '图片生成视频',
            free: false,
            pay: true,
         },
         {
            key: '6',
            function: '正品、优惠、包邮图标添加',
            free: false,
            pay: true,
         },
         {
            key: '7',
            function: '图片容量',
            free: "无限(取决于您的电脑磁盘)",
            pay: "无限(取决于您的电脑磁盘)",
         },
         {
            key: '8',
            function: '图片翻译',
            free: "购买即可使用",
            pay: "购买即可使用",
         },
         {
            key: '9',
            function: '文字翻译',
            free: "购买即可使用",
            pay: "购买即可使用",
         },
         {
            key: '10',
            function: '订单管理',
            free: true,
            pay: true,
         },

         // {
         //    key: '10',
         //    function: '按图搜索比较最低价（开发中）',
         //    free: false,
         //    pay: true,
         // },
      ];

      var data_package = [
         {
            key: '1',
            function: '可授权店铺数',
            free: "2个/平台",
            vip1: '5个/平台',
            vip2: '50个/平台',
            vip3: '100个/平台',
            vip4: '200个/平台',
         },
         {
            key: '2',
            function: '图片空间',
            free: "不限",
            vip1: '不限',
            vip2: '不限',
            vip3: '不限',
            vip4: '不限',
         },
         {
            key: '3',
            function: '产品采集',
            free: "可采10个/天",
            vip1: '18888条/月',
            vip2: '188888条/月',
            vip3: '388888条/月',
            vip4: '888888条/月',
         },
         {
            key: '4',
            function: '产品发布',
            free: true,
            vip1: true,
            vip2: true,
            vip3: true,
            vip4: true,
         },
         {
            key: '5',
            function: '图片生成视频',
            free: false,
            vip1: true,
            vip2: true,
            vip3: true,
            vip4: true,
         },
         {
            key: '6',
            function: '正品、优惠、包邮图标添加',
            free: false,
            vip1: true,
            vip2: true,
            vip3: true,
            vip4: true,
         },
         {
            key: '7',
            function: '图片容量',
            free: "无限(取决于您的电脑磁盘)",
            vip1: "无限(取决于您的电脑磁盘)",
            vip2: "无限(取决于您的电脑磁盘)",
            vip3: "无限(取决于您的电脑磁盘)",
            vip4: "无限(取决于您的电脑磁盘)",
         },
         {
            key: '8',
            function: '图片翻译',
            free: "购买即可使用",
            vip1: "购买即可使用",
            vip2: "购买即可使用",
            vip3: "购买即可使用",
            vip4: "购买即可使用",
         },
         {
            key: '9',
            function: '文字翻译',
            free: "购买即可使用",
            vip1: "购买即可使用",
            vip2: "购买即可使用",
            vip3: "购买即可使用",
            vip4: "购买即可使用",
         },
         {
            key: '10',
            function: '订单管理',
            free: true,
            vip1: true,
            vip2: true,
            vip3: true,
            vip4: true,
         },

         // {
         //    key: '10',
         //    function: '按图搜索比较最低价（开发中）',
         //    free: false,
         //    vip1: true,
         // vip2: true,
         // vip3: true,
         // vip4: true,
         // },
      ];

      const options = [
         // {
         //    label: <>关注大卖粉丝</>, value: 'fans'
         // },
         {
            label: <>图片翻译</>, value: 'translateImg'
         }, //, disabled: true 
      ];

      const bannerItemData = {
         "title": "爆单ERP订购中心",
         "text": "按照店铺订购：订购店铺数量和时间随心所欲，按需订购，订购店铺数量和时间随心所欲，按需订购订购店铺数量和时间随心所欲，按需订购\n按套餐订购：性价比最高，解锁更多功能特性，性价比最高，解锁更多功能特性性价比最高，解锁更多功能特性\n资源包：AI文字图片翻译，价格实惠，AI文字图片翻译，价格实惠，AI文字图片翻译，价格实惠",
         "img": "/img/pro/bannerIcon3.png",
         "btn1": { "text": "下载试用", "url": window.location.origin + "/download" },
      }

      const bannerData = [
         {
            "img": "/img/pro/banner_buy.png",
            "btn1": { "text": "免费试用", "url": window.location.origin + "/download" },
            // "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
      ]
      const bannerData_phone = [
         {
            "img": "/img/pro/banner_buy_phone.png",
            "btn1": { "text": "免费试用", "url": window.location.origin + "/download" },
            // "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
      ]


      var groupCodeUrl = window.location.origin + "/price?gc=" + groupCode

      var buttonSize = isPhone ? "middle" : "large"  //large  middle   small 

      return (
         <React.Fragment>

            <MyImageBanner bannerData={isPhone ? bannerData_phone : bannerData} height={isPhone ? "600px" : "400px"} />

            {
               websocket_pay ?
                  <ReactWebsocket
                     url={CommonUtils.baseWs + "/websocket/" + CommonUtils.getLocalStorage("token") + "/" + (new Date()).getTime() + "/0"}
                     onMessage={this.onMessage} //支付   ---  接受信息的回调
                     onOpen={this.onOpen} //websocket打开 
                     onClose={this.onClose} //websocket关闭
                     reconnect={true}
                     debug={true}
                     ref={Websocket => {
                        this.refWebSocket = Websocket;
                     }}
                  />
                  : null
            }

            {
               openWebsocket_group ?
                  <ReactWebsocket
                     url={CommonUtils.baseWs + "/websocket/" + CommonUtils.getLocalStorage("token") + "/" + (new Date()).getTime() + "/" + groupCode}
                     onMessage={this.onMessageGroup} //拼团  --- 接受信息的回调
                     onOpen={this.onOpen} //websocket打开 
                     onClose={this.onClose} //websocket关闭
                     reconnect={true}
                     debug={true}
                     ref={Websocket => {
                        this.refWebSocket_group = Websocket;
                     }}
                  />
                  : null
            }

            <Modal
               open={showModal}
               onCancel={() => this.setStateKeyValue("showModal", false)}
               footer={null}
               centered
            >
               <Result
                  status="success"
                  title="订购成功!"
                  subTitle="ERP软件需点击刷新，才能生效.（个人中心-->我的-->刷新）"
                  extra={[
                     <NavLink to="/user/profile">
                        <Button key="buy" type="primary">前往个人主页</Button>
                     </NavLink>
                     ,
                     <Button key="console" onClick={() => this.setStateKeyValue("showModal", false)}>
                        关闭
                     </Button>
                  ]}
               />
            </Modal>


            <Modal
               title={"扫码支付-" + payTitle}
               open={showModal_payQrCode}
               onCancel={() => this.setStateKeyValue("showModal_payQrCode", false)}
               onOk={() => this.setStateKeyValue("showModal_payQrCode", false)}
               cancelText="取消"
               okText="支付完毕"
               centered
            >
               {
                  payQrCodeUrl ?
                     <div style={{ textAlign: "center" }}>
                        <div style={{ textAlign: "center", display: "inline-block" }}>
                           <QRCode value={payQrCodeUrl || '-'} errorLevel={"H"} icon={"/img/wechat_logo.png"} />
                           微信扫码支付
                        </div>
                        {
                           isPhone && isWechat ?
                              <div style={{ margin: "20px 0px" }}>
                                 提示：用手机浏览器发起支付，可自动唤起微信支付。
                              </div>
                              : null
                        }
                        <div style={{ margin: "20px 0px" }}>
                           <Checkbox checked></Checkbox>
                           <span> 我已阅读并同意</span> <span className="register_xieyi">
                              <NavLink to="/agreement" target="_blank">
                                 <span  >《爆单ERP系列会员服务协议》</span>
                              </NavLink>
                           </span>
                        </div>
                     </div>
                     : <span>获取支付码失败，请重试！</span>
               }

            </Modal>


            <Modal
               title={"订购统计选品"}
               open={showModal_analysis_data}
               // open={true}
               onCancel={() => this.setStateKeyValue("showModal_analysis_data", false)}
               onOk={this.buyStatisticsOzonFun}
               width={600}
               cancelText="取消"
               okText="立即订购"
               centered
            >
               <div className="messageBox">
                  <span className="messageTitle">资源包:</span>
                  <span className="messageText">统计选品</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">功能说明:</span>
                  <span className="messageText">实时统计ozon等平台的销售数据，洞悉各个类目的销售情况，探寻潜力类目和潜力商品，助您早日爆单。</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">订购套餐:</span>
                  <Radio.Group defaultValue={1}  onChange={(e) => this.analysis_data_ozonChangeFun("analysis_data_ozon_Count", e.target.value)} size={buttonSize}>
                     <Radio.Button value={1}><Tooltip title="可以查看前100条的各项数据">top100</Tooltip></Radio.Button>
                     <Radio.Button value={2}><Tooltip title="可以查看前500条的各项数据">top500</Tooltip></Radio.Button>
                     <Radio.Button value={3}><Tooltip title="可以查看前1000条的各项数据">top1000</Tooltip></Radio.Button>
                     <Radio.Button value={4}><Tooltip title="可以查看所有相关的统计数据">不限制</Tooltip></Radio.Button>
                  </Radio.Group>
               </div>
               <div className="listLine">
                  <span className="textTitle">订购周期：</span>
                  <Radio.Group defaultValue={1} onChange={(e) => this.analysis_data_ozonChangeFun("analysis_data_ozon_Time", e.target.value)} size={buttonSize}>
                     <Radio.Button value={1}>一个月</Radio.Button>
                     <Radio.Button value={3}>三个月</Radio.Button>
                     <Radio.Button value={6}>半年</Radio.Button>
                     <Radio.Button value={12}>一年</Radio.Button>
                     <Radio.Button value={36}>三年</Radio.Button>
                  </Radio.Group>
               </div>

               <div className="messageBox">
                  <span className="messageTitle">应付金额:</span>
                  <span className="textPrice">￥{analysis_data_ozon_price}
                     <span className="textOriginalPrice" style={{ position: 'absolute' }}>￥{analysis_data_ozon_oldPrice}</span></span>
               </div>

            </Modal>

            <Modal
               title={"订购图片翻译"}
               open={showModal_translate}
               // open={true}
               onCancel={() => this.setStateKeyValue("showModal_translate", false)}
               onOk={this.buyTranslateImage}
               width={600}
               cancelText="取消"
               okText="立即订购"
               centered
            >
               <div className="messageBox">
                  <span className="messageTitle">资源包:</span>
                  <span className="messageText">图片翻译(实时)</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">功能说明:</span>
                  <span className="messageText">调用人工智能AI图片翻译模型，每翻译1张图片算为1次。该功能平台内通用。（一分钱一张,全网最便宜）</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">订购套餐:</span>
                  <div>
                     <Radio.Group value={imageTranslateCount} onChange={(e) => this.setStateKeyValue("imageTranslateCount", e.target.value)} size={buttonSize}>
                        <Radio.Button value={1000}>一千次</Radio.Button>
                        <Radio.Button value={3000}>三千次</Radio.Button>
                        <Radio.Button value={10000}>一万次</Radio.Button>
                        <Radio.Button value={30000}>三万次</Radio.Button>
                        <Radio.Button value={50000}>五万次</Radio.Button>
                        <Radio.Button value={100000}>十万次</Radio.Button>
                        <Radio.Button value={150000}>十五万次</Radio.Button>
                        <Radio.Button value={200000}>二十万次</Radio.Button>
                        <Radio.Button value={500000}>五十万次</Radio.Button>
                        <Radio.Button value={1000000}>一百万次</Radio.Button>
                        <Radio.Button value={2000000}>两百万次</Radio.Button>
                     </Radio.Group>
                  </div>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">应付金额:</span>
                  <span className="textPrice">￥{parseInt(0.018 * imageTranslateCount)} <span className="textOriginalPrice" style={{ position: 'absolute' }}>￥{parseInt(0.04 * imageTranslateCount)}</span></span>
               </div>

            </Modal>

            <Modal
               title={"订购文本翻译"}
               open={showModal_translate_text}
               onCancel={() => this.setStateKeyValue("showModal_translate_text", false)}
               onOk={this.buyTranslateImageText}
               width={600}
               cancelText="取消"
               okText="立即订购"
               centered
            >
               <div className="messageBox">
                  <span className="messageTitle">资源包:</span>
                  <span className="messageText">文本翻译(实时)</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">功能说明:</span>
                  <span className="messageText">调用百度翻译商业接口，翻译更准确，效率更高。字符数量的统计以翻译的源语言字符长度为标准。一个汉字、一个英文字母、一个标点符号、空格等均记为一个字符。该功能平台内通用。</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">订购套餐:</span>
                  <div>
                     <Radio.Group value={textTranslateCount} onChange={(e) => this.setStateKeyValue("textTranslateCount", e.target.value)} size={buttonSize}>
                        <Radio.Button value={1000000}>一百万次</Radio.Button>
                        <Radio.Button value={5000000}>五百万次</Radio.Button>
                        <Radio.Button value={10000000}>一千万次</Radio.Button>
                     </Radio.Group>
                  </div>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">应付金额:</span>
                  <span className="textPrice">￥{parseInt(0.00004 * textTranslateCount)}</span>
               </div>

            </Modal>

            <Modal
               title={"订购关注大卖粉丝"}
               open={showModal_follow_fans}
               // open={true}
               onCancel={() => this.setStateKeyValue("showModal_follow_fans", false)}
               onOk={this.buyfans}
               width={600}
               centered
               cancelText="取消"
               okText="立即订购"
            >
               <div className="messageBox">
                  <span className="messageTitle">资源包:</span>
                  <span className="messageText">关注大卖店铺的粉丝（只对shopee有效）</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">功能说明:</span>
                  <span className="messageText">关注大卖店铺的粉丝，当粉丝会关之后，您上新产品时，平台会将您的新品推送给粉丝，增加曝光量。同品类的店铺的粉丝，是您的精准潜在客户。</span>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">订购套餐:</span>
                  <div>
                     {/* <Radio.Group value={textTranslateCount} onChange={(e) => this.setStateKeyValue("textTranslateCount", e.target.value)}>
                        <Radio.Button value={1000000}>一百万次</Radio.Button>
                        <Radio.Button value={5000000}>五百万次</Radio.Button>
                        <Radio.Button value={10000000}>一千万次</Radio.Button>
                     </Radio.Group> */}


                     <div className="listLine" >
                        <span className="textTitle">订购店铺：</span>
                        <Radio.Group value={fansCount} onChange={(e) => this.setStateKeyValue("fansCount", e.target.value)} size={buttonSize}>
                           <Radio.Button value={1}>1</Radio.Button>
                           <Radio.Button value={2}>2</Radio.Button>
                           <Radio.Button value={5}>5</Radio.Button>
                           <Radio.Button value={10}>10</Radio.Button>
                           <Input className="numberInputStyle" placeholder="输入店铺数" type="number" size={buttonSize}
                              onChange={(e) => this.setStateKeyValue("fansCount", e.target.value)} />
                           <Text style={{ lineHeight: "32px" }}>个店铺</Text>
                        </Radio.Group>

                     </div>

                     <div className="listLine">
                        <span className="textTitle">订购周期：</span>
                        <Radio.Group value={fansTime} onChange={(e) => this.setStateKeyValue("fansTime", e.target.value)} size={buttonSize}>
                           <Radio.Button value={1}>一个月</Radio.Button>
                           <Radio.Button value={6}>半年</Radio.Button>
                           <Radio.Button value={12}>一年</Radio.Button>
                           <Radio.Button value={36}>三年</Radio.Button>
                        </Radio.Group>
                     </div>


                  </div>
               </div>
               <div className="messageBox">
                  <span className="messageTitle">应付金额:</span>
                  <span className="textPrice">￥{parseInt(5 * fansCount * fansTime)}</span>
               </div>

            </Modal>

            <Modal title="拼团信息" centered open={showModal_buygroup_info}
               onCancel={() => this.setStateKeyValue("showModal_buygroup_info", false)}
               onOk={() => this.setStateKeyValue("showModal_buygroup_info", false)}
               okText="确认"
               cancelText="取消"
            >

               {
                  groupCode ?
                     <>
                        <div className="listLine">
                           <span>团购号码：</span>{groupCode}
                           <Tooltip title={"复制"} placement="top">
                              <CopyOutlined onClick={() => CommonUtils.copyData(groupCode)} />
                           </Tooltip>
                        </div>
                        <div className="listLine">
                           <span style={{ float: 'inline-start' }}>有效期：</span>
                           <span className="priceTime"><Countdown value={(new Date().getTime() + expireStamp)} format="D 天 H 时 m 分 s 秒" /></span>
                        </div>

                        <div className="listLine">
                           <span style={{ float: 'inline-start' }}>二维码：</span>
                           <QRCode value={groupCodeUrl || '-'} errorLevel={"H"} icon={"/favicon.ico"} />
                        </div>
                        <div className="listLine">
                           <span>团购链接：</span>
                           <Text style={{ maxWidth: '280px', wordWrap: 'break-word' }} ellipsis>{groupCodeUrl} </Text>
                           <Tooltip title={"复制"} placement="top">
                              <CopyOutlined onClick={() => CommonUtils.copyData(groupCodeUrl)} />
                           </Tooltip>
                        </div>
                        <div className="listLine">
                           <span>提示：</span>
                           <Text style={{ maxWidth: '280px', wordWrap: 'break-word' }} ellipsis>请用手机浏览器扫码或者打开链接。 </Text>
                        </div>
                        <div className="listLine">
                           <span style={{ float: 'inline-start' }}>参团用户：</span>
                           <Avatar.Group
                              maxCount={6}
                              // size="large"
                              maxStyle={{
                                 color: '#f56a00',
                                 backgroundColor: '#fde3cf',
                              }}
                              style={{ verticalAlign: "middle", }}
                           >
                              {
                                 new100Phone.map(item => {
                                    return (

                                       <Tooltip title={"手机尾号：" + item} color='blue' placement="top">
                                          <Avatar style={{ backgroundColor: '#87d068' }}>
                                             {item}
                                          </Avatar>
                                       </Tooltip>


                                    )
                                 })
                              }
                           </Avatar.Group>
                        </div>


                        <div className="listLine">
                           <span>团购价：</span>
                           <span className="textdetail">
                              {groupDiscountPrice ?
                                 <span>
                                    <div style={{ display: 'inline-block' }}>
                                       <span className="textPrice">￥{groupDiscountPrice}</span>
                                       <div className="textOriginalPrice" style={{ float: 'inline-end' }}>￥{payablePrice}</div>
                                    </div>
                                    （{groupDiscount}折）
                                 </span>

                                 : "无,  将团购链接发给他人，团购的人越多，折扣越大。"}</span>
                        </div>

                     </>
                     :
                     <>
                        <div className="listLine">
                           <span>团购状态：未参团</span>
                        </div>
                        <div className="listLine">
                           <Button type="primary" onClick={this.makeGroupBuyingFun} >我发起拼团</Button>
                        </div>
                     </>


               }






            </Modal>

            <Drawer
               // title="标题"
               placement={'bottom'} //抽屉的方向
               closable={false}
               onClose={() => this.setStateKeyValue('showBuyDrawer', false)}
               open={showBuyDrawer}
               height={"78%"}
            >
               <div className="listLine">
                  <span className="textTitle">订购平台：</span>
                  <Radio.Group value={buyPlatform} onChange={(e) => this.refreshPlatformFun(e.target.value)} size={buttonSize}>
                     <Radio.Button value={"shopCommon"}>
                        <img class="smallIcon" src="/img/shopee_logo.png"></img>
                        Shopee
                     </Radio.Button>
                     <Radio.Button value={"shopTiktok"}>
                        <img class="smallIcon" src="/img/tiktok_logo.png"></img>
                        Tiktok
                     </Radio.Button>
                     <Radio.Button value={"shopOzon"}>
                        <img class="smallIcon" src="/img/ozon_logo.png"></img>
                        Ozon
                     </Radio.Button>

                  </Radio.Group>
               </div>
               <div className="listLine" >
                  <div className="textTitle">订购店铺：</div>
                  {/*  size={isPhone ? "small" : "middle"} */}
                  <Radio.Group value={shopCount} onChange={(e) => this.setShopCountFun(e.target.value)} size={buttonSize}>
                     <Radio.Button value={1}>1</Radio.Button>
                     <Radio.Button value={2}>2</Radio.Button>
                     <Radio.Button value={5}>5</Radio.Button>
                     <Radio.Button value={10}>10</Radio.Button>
                     <Radio.Button value={20}>20</Radio.Button>
                     <Radio.Button value={30}>30</Radio.Button>
                     <Radio.Button value={50}>50</Radio.Button>
                     <Radio.Button value={100}>100</Radio.Button>
                     <Radio.Button value={200}>200</Radio.Button>
                     {/* addonAfter="个店铺"  */}
                     <Input value={shopCount} className="numberInputStyle" placeholder="输入店铺数" type="number" size={buttonSize}
                        onChange={(e) => this.shopCountInputFun(e.target.value)} />
                     <Text style={{ lineHeight: "32px" }}>个店铺</Text>
                  </Radio.Group>

               </div>


               <div className="listLine">
                  <span className="textTitle">订购周期：</span>
                  <Radio.Group value={vipTime} onChange={(e) => this.setVipTimeFun(e.target.value)} size={buttonSize}>
                     <Radio.Button value={1}>一个月</Radio.Button>
                     <Radio.Button value={3}>一季度(9.8折)</Radio.Button>
                     <Radio.Button value={6}>半年(8.8折)</Radio.Button>
                     <Radio.Button value={12}>一年(7.8折)</Radio.Button>
                     {/* <Radio.Button value={36}>三年(6.8折)</Radio.Button> */}
                  </Radio.Group>
               </div>

               <div className="listLine">
                  <Popconfirm
                     title="说明"
                     // description=<div><div>关注大卖粉丝：支持shopee虾皮平台，单价：1元/店/月。</div><div>图片翻译： 平台不限，单价：1元/店/500张。</div></div>
                     description=<div><div>图片翻译： 平台不限，单价：1元/店/500张。</div></div>
                     showCancel={false}
                     okText={"确定"}
                     icon={<QuestionCircleOutlined style={{ color: 'grey', }} />}
                  >
                     <span className="textTitle popconfirmStyle">资源包 <QuestionCircleOutlined style={{ fontSize: "14px", marginLeft: "3px" }} />：</span>
                  </Popconfirm>
                  <Checkbox.Group options={options} onChange={this.onChangeCheckbox} value={resourcePackageCheckValue} />
               </div>

               <div className="listLine">
                  <span className="textTitle lineHeight29">代金券：</span>
                  <span className="lineHeight29 couponTextBase">共有￥<span className="couponTextBlack">{couponTotal_noUse}</span> 元代金券，</span>
                  {payByCoupon ?
                     <Popconfirm
                        title="说明"
                        description=<div>代金券可抵扣的额度不是固定的，随着店铺数和订购周期的增加而增加。</div>
                        showCancel={false}
                        okText={"确定"}
                        icon={<QuestionCircleOutlined style={{ color: 'grey', }} />}
                     >
                        <span className="lineHeight29 couponTextBase" style={{ marginLeft: "10px", cursor: "pointer" }}>可抵￥<span className="couponTextBlue">{payByCoupon}</span> 元</span>
                     </Popconfirm>


                     : null}
               </div>

               {
                  // isCouponBuy ?
                  //    <>
                  //       <div className="listLine">
                  //          <span className="textTitle">团购折扣：</span>
                  //          <div>
                  //             <Tag className="tagStyle" style={{ backgroundColor: '#EEF5FF', color: '#0569FF' }}>2人9.5折</Tag>
                  //             <Tag className="tagStyle" style={{ backgroundColor: '#EDFFF7', color: '#10D080' }}>3人以上8.8折</Tag>

                  //             {/* <Tag className="tagStyle" style={{ backgroundColor: '#EEF5FF', color: '#0569FF' }}>2人9折</Tag>
                  //             <Tag className="tagStyle" style={{ backgroundColor: '#EDFFF7', color: '#10D080' }}>3人8折</Tag>
                  //             <Tag className="tagStyle" style={{ backgroundColor: '#FFEFEF', color: '#FB5352' }}>4人7折</Tag>
                  //             <Tag className="tagStyle" style={{ backgroundColor: '#FDF6EC', color: '#FB9952' }}>5人6折</Tag>
                  //             <Tag className="tagStyle" style={{ backgroundColor: '#F8ECFD', color: '#CF52FB' }}>6人以上5折</Tag> */}
                  //          </div>
                  //       </div>
                  //       <div className="listLine">
                  //          <span className="textTitle lineHeight29">团购号码：</span>
                  //          <Input className="numberInputStyle" style={{ width: "100px" }} value={groupCodeInput} placeholder="" type="number"
                  //             onChange={(value) => this.groupBuyingCodeFun(value)} />
                  //          <InfoCircleOutlined onClick={() => this.setStateKeyValue("showModal_buygroup_info", true)} style={{ marginRight: '5px', lineHeight: '30px' }} />
                  //          <Button type="primary" onClick={this.makeGroupBuyingFun}>我发起拼团</Button>
                  //       </div>
                  //    </>
                  //    : null
               }

               <Button type="primary" className={isPhone ? "btnDrawerBuy btnBuy buyBtnStyleRed" : "btnDrawerBuyComputer btnBuy buyBtnStyleRed"}
                  style={isPhone ? { height: "48px" } : {}}
                  onClick={() => this.shopBuyingFun(isCouponBuy)}>立即支付 ￥{isCouponBuy ? payablePrice : payMoney_noCoupon}</Button>

            </Drawer>




            <div className="rootBox">
               <div className="rootContent">
                  <Tabs
                     activeKey={TabsActiveKey}
                     type="card"
                     // size="large"
                     size={isPhone ? "small" : "large"}
                     // centered //标签居中展示
                     onTabClick={this.onTabClickFun}
                     items={[
                        {
                           label: '软件订购',
                           key: "1",
                           children: <div className="tabContentBox">
                              <div className="listLine">
                                 <Radio.Group className="" value={buyShopType} onChange={(e) => this.setStateKeyValue("buyShopType", e.target.value)} size={"large"}>
                                    <Radio.Button value={"buyShopCount"} className="buyShopType">按店铺订购</Radio.Button>
                                    <Radio.Button value={"buyShopPackage"} className="buyShopType">按套餐订购</Radio.Button>

                                 </Radio.Group>
                              </div>
                              {
                                 buyShopType == "buyShopCount" ?
                                    <>
                                       <div className="listLine">
                                          <span className="textTitle">订购平台：</span>
                                          {/* <Image preview={false} className="smallIcon" src="/img/shopee_logo.png"></Image>
                              <span className="textdetail">Shopee</span>
                              <Image preview={false} className="smallIcon" src="/img/ozon_logo.png"></Image>
                              <span className="textdetail">Ozon</span> */}

                                          <Radio.Group value={buyPlatform} onChange={(e) => this.refreshPlatformFun(e.target.value)} size={buttonSize}>
                                             <Radio.Button value={"shopCommon"}>
                                                <img class="smallIcon" src="/img/shopee_logo.png"></img>
                                                Shopee
                                             </Radio.Button>
                                             <Radio.Button value={"shopTiktok"}>
                                                <img class="smallIcon" src="/img/tiktok_logo.png"></img>
                                                Tiktok
                                             </Radio.Button>
                                             <Radio.Button value={"shopOzon"}>
                                                <img class="smallIcon" src="/img/ozon_logo.png"></img>
                                                Ozon
                                             </Radio.Button>

                                          </Radio.Group>

                                       </div>
                                       <div className="listLine" >
                                          <span className="textTitle">订购店铺：</span>
                                          {/* //size={isPhone ? "small" : "middle"} */}
                                          <Radio.Group value={shopCount} onChange={(e) => this.setShopCountFun(e.target.value)} size={buttonSize}>

                                             {
                                                isPhone ?
                                                   <>
                                                      <Radio.Button value={1}>1</Radio.Button>
                                                      <Radio.Button value={5}>5</Radio.Button>
                                                      <Radio.Button value={10}>10</Radio.Button>
                                                      <Radio.Button value={100}>100</Radio.Button>
                                                   </>
                                                   :
                                                   <>
                                                      <Radio.Button value={1}>1</Radio.Button>
                                                      <Radio.Button value={2}>2</Radio.Button>
                                                      <Radio.Button value={5}>5</Radio.Button>
                                                      <Radio.Button value={10}>10</Radio.Button>
                                                      <Radio.Button value={20}>20</Radio.Button>
                                                      <Radio.Button value={30}>30</Radio.Button>
                                                      <Radio.Button value={50}>50</Radio.Button>
                                                      <Radio.Button value={100}>100</Radio.Button>
                                                      <Radio.Button value={200}>200</Radio.Button>
                                                   </>
                                             }

                                          </Radio.Group>
                                          {
                                             isPhone ? <RightOutlined onClick={() => this.setShowBuyDrawer(true)} /> :
                                                <>
                                                   <Input value={shopCount} className="numberInputStyle" placeholder="输入店铺数" type="number" size={buttonSize}
                                                      onChange={(e) => this.shopCountInputFun(e.target.value)} />
                                                   <Text style={{ lineHeight: "32px" }}>个店铺</Text>
                                                </>
                                          }

                                       </div>

                                       <div className="listLine">
                                          <span className="textTitle">订购周期：</span>
                                          <Radio.Group value={vipTime} onChange={(e) => this.setVipTimeFun(e.target.value)} size={buttonSize}>
                                             {isPhone ?
                                                <>
                                                   <Radio.Button value={1}>1个月</Radio.Button>
                                                   <Radio.Button value={3}>1季度</Radio.Button>
                                                   <Radio.Button value={12}>1年</Radio.Button>
                                                </>
                                                :
                                                <>
                                                   <Radio.Button value={1}>一个月</Radio.Button>
                                                   <Radio.Button value={3}>一季度(9.8折)</Radio.Button>
                                                   <Radio.Button value={6}>半年(8.8折)</Radio.Button>
                                                   <Radio.Button value={12}>一年(7.8折)</Radio.Button>
                                                   {/* <Radio.Button value={36}>三年(6.8折)</Radio.Button> */}
                                                </>}
                                          </Radio.Group>
                                          {
                                             isPhone ? <RightOutlined onClick={() => this.setShowBuyDrawer(true)} /> : null
                                          }
                                       </div>

                                       <div className="listLine">
                                          <Popconfirm
                                             title="说明"
                                             // description=<div><div>关注大卖粉丝：支持shopee虾皮平台，单价：1元/店/月。</div><div>图片翻译： 平台不限，单价：1元/店/500张。</div></div>
                                             description=<div><div>图片翻译： 平台不限，单价：1元/店/500张。</div></div>
                                             showCancel={false}
                                             okText={"确定"}
                                             icon={<QuestionCircleOutlined style={{ color: 'grey', }} />}
                                          >
                                             <span className="textTitle popconfirmStyle">资源包<QuestionCircleOutlined style={{ fontSize: "14px", marginLeft: "3px" }} />：</span>
                                          </Popconfirm>
                                          <Checkbox.Group options={options} onChange={this.onChangeCheckbox} value={resourcePackageCheckValue} />
                                       </div>

                                       <div className="listLine">
                                          <span className="textTitle lineHeight29">代金券：</span>
                                          <span className="lineHeight29 couponTextBase">共有￥<span className="couponTextBlack">{couponTotal_noUse}</span> 元代金券，</span>
                                          {payByCoupon ?
                                             <Popconfirm
                                                title="说明"
                                                description=<div>代金券可抵扣的额度不是固定的，随着店铺数和订购周期的增加而增加。</div>
                                                showCancel={false}
                                                okText={"确定"}
                                                icon={<QuestionCircleOutlined style={{ color: 'grey', }} />}
                                             >
                                                <span className="lineHeight29 couponTextBase" style={{ marginLeft: "10px", cursor: "pointer" }}>可抵￥<span className="couponTextBlue">{payByCoupon}</span> 元</span>
                                             </Popconfirm>


                                             : null}

                                       </div>
                                       <div className="listLine">
                                          <span className="textTitle lineHeight29">支付金额：</span>
                                          <span className="lineHeight29 couponTextBase"><span className="couponTextRed">￥{originalPrice}</span> 元</span>
                                       </div>
                                       <div className="listLine">
                                          <div>
                                             <span className="textTitle"></span>

                                             <span className="textPrice">
                                                <Button className="btnBuy buyBtnStyle" type="primary" onClick={() => this.shopBuyingFun(false)}>
                                                   折后￥<span className="buyBtnMoney">{payMoney_noCoupon}</span>
                                                   {/* <span className="textOriginalPrice" >￥{originalPrice}</span> */}
                                                   <span className="buyLowerRate" >-{((originalPrice - payMoney_noCoupon) / originalPrice * 100).toFixed(0)}%</span>
                                                </Button>
                                                <Button className="btnBuy buyBtnStyle buyBtnStyleRed" type="primary" onClick={() => this.shopBuyingFun(true)} style={{ marginLeft: "5px" }}>
                                                   <div>
                                                      券后￥<span className="buyBtnMoney">{payablePrice}</span>
                                                      <span className="buyLowerRate buyLowerRateRed" >-{((originalPrice - payablePrice) / originalPrice * 100).toFixed(0)}%</span>
                                                   </div>
                                                </Button>
                                             </span>

                                          </div>
                                       </div>

                                       <br /><br /><br />
                                       <br /><br /><br />
                                       <Divider id='part-permissions'>功能权限</Divider>
                                       <br />
                                       <Table columns={columns} dataSource={data} pagination={false} />


                                       <br /><br /><br />
                                       <Divider id='part-ordering-instructions'>订购说明</Divider>
                                       <Text >
                                          1、若您要升级店铺数，请选择大于等于此平台当前已授权店铺数加上准备授权店铺数的订购额度；<br />
                                          举例：已订购了10个店铺，现在要再绑定3个店铺，那么订购数量就选择或输入13；<br />
                                          升级或降级店铺数，系统都会将剩余的/1店/1天权益叠加到新的时间线中（以新的店铺数为基数算出天数，再+1天送一天）。
                                          <br /><br />
                                          2、若您未到期要续费，请选择与此平台最近一次订购相同的订购额度；<br />
                                          举例：最近一次订购了20个店铺，现在要提前续费，那么订购数量就选择或输入20；<br /><br />
                                          {/* 3、团购两种方式（团购号码有效期为1天）：<br />
                  （1）、输入他人分享的团购号码，即可参团；<br />
                  （2）、点击"我发起拼团"按钮，将团购号码、团购链接、二维码任意一个分享给其他用户，即可。<br />
                  4、单独购买和团购的购买结果是一样的，只是团购会在单独购买的基础上，再打折。<br /> 
                  5、优惠券或团购的最低金额为1元。<br />*/}
                                          3、代金券可抵扣的额度不是固定的，随着店铺数和订购周期的增加而增加。<br />
                                          4、代金券的抵扣，系统自动从最早的代金券开始使用。代金券一次最多只能使用1000张代金券，总额不限。<br />
                                          5、代金券抵扣时，如果某一张只用了一部分额度，该张代金券会被标记为已使用，但是不影响其他未使用到的代金券。<br />
                                          （例如：有10张100元的代金券，共1000元。使用的时候抵扣了880元，剩下的就是100元代金券。）<br />
                                          6、订购周期有打折的时候，代金券的抵扣是在打折之前进行的。公式为：支付价格 = （原价 * 店铺数 * 月份数 - 可抵扣额） * 折扣率

                                       </Text>
                                       <br />
                                    </>
                                    : <>
                                       <div className="listLine buyPackageHeader" style={{ display: "block" }}>
                                          <Row justify={"center"} align={"middle"} style={{ padding: "0px 0px 20px 0px" }}>
                                             {
                                                isPhone ? null : <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className="textAlignCenter"></Col>
                                             }
                                             <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className="textAlignCenter">
                                                <div className="buyPackageHeaderTitle">免费版</div>
                                                <div className="buyPackageHeaderText">含基础功能，永久免费</div>
                                             </Col>
                                             <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className="textAlignCenter">
                                                <div className="buyPackageHeaderTitle">VIP 1</div>
                                                <div className="buyPackageHeaderText">¥28/月</div>
                                                <Button className="buyPackageHeaderBtn" size={isPhone ? "small" : "middle"} onClick={() => this.buyPackageFun("vip1")}>订购</Button>
                                             </Col>
                                             <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className="textAlignCenter">
                                                <div className="buyPackageHeaderTitle">VIP 2</div>
                                                <div className="buyPackageHeaderText">¥1188/季</div>
                                                <Button className="buyPackageHeaderBtn" size={isPhone ? "small" : "middle"} onClick={() => this.buyPackageFun("vip2")}>订购</Button>
                                             </Col>
                                             <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className="textAlignCenter">
                                                <div className="buyPackageHeaderTitle">VIP 3</div>
                                                <div className="buyPackageHeaderText">¥5188/半年</div>
                                                <Button className="buyPackageHeaderBtn" size={isPhone ? "small" : "middle"} onClick={() => this.buyPackageFun("vip3")}>订购</Button>
                                             </Col>
                                             <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className="textAlignCenter">
                                                <div className="buyPackageHeaderTitle">VIP 4</div>
                                                <div className="buyPackageHeaderText">¥18888/年</div>
                                                <Button className="buyPackageHeaderBtn" size={isPhone ? "small" : "middle"} onClick={() => this.buyPackageFun("vip4")}>订购</Button>
                                             </Col>

                                          </Row>
                                       </div>

                                       <div className="listLine" style={{ display: "block" }}>
                                          <Table columns={columns_package} dataSource={data_package} pagination={false} />
                                       </div>
                                    </>
                              }


                           </div>,
                        },
                        {
                           label: '资源包',
                           key: "2",
                           children: <>
                              <Card hoverable className="cardItem" cover={<img alt="example" src="/img/translate-img.png" style={{ borderRadius: '0px' }} />} bodyStyle={{ padding: '0px' }} >
                                 {/* <Card.Meta title="图片翻译" description="自动识别图片上文字语言，译为多国语言，无需P图。<br/>一键实现图片翻译，降低时间成本。\n超高性价比，降低资金成本。" style={{ margin: '12px 12px 0px 12px', minHeight: '99px', whiteSpace: "pre-wrap" }} /> */}
                                 <Card.Meta title="图片翻译" description="" style={{ margin: '12px 12px 0px 12px' }} />
                                 <div className="resourceContent">自动识别图片上文字语言，译为多国语言，无需P图。<br /><br />一键实现图片翻译，降低时间成本。<br /><br />超高性价比，降低资金成本。</div>
                                 <div className="textPrice">￥18 <span className="textOriginalPrice" style={{ position: 'absolute' }}>￥40</span></div>
                                 <Button type="primary" className="cardBtn" onClick={() => this.setStateKeyValue("showModal_translate", true)}>立即购买</Button>
                              </Card>

                              <Card hoverable className="cardItem" cover={<img alt="example" src="/img/translate-text.png" style={{ borderRadius: '0px' }} />} bodyStyle={{ padding: '0px' }} >
                                 {/* <Card.Meta title="文本翻译" description="百度文本翻译。支持200+种语言。" style={{ margin: '12px 12px 0px 12px', minHeight: '99px' }} /> */}
                                 <Card.Meta title="文字翻译" description="" style={{ margin: '12px 12px 0px 12px' }} />
                                 <div className="resourceContent">自动识别文字语言，译为多国语言。<br /><br />一键实现图片翻译，降低时间成本。<br /><br />超高性价比，降低资金成本。</div>
                                 <div className="textPrice">￥40</div>
                                 <Button type="primary" className="cardBtn" onClick={() => this.setStateKeyValue("showModal_translate_text", true)}>立即购买</Button>
                              </Card>

                              <Card hoverable className="cardItem" cover={<img alt="example" src="/img/resource_bundle_statistics.png" style={{ borderRadius: '0px' }} />} bodyStyle={{ padding: '0px' }} >
                                 <Card.Meta title="统计选品" description="" style={{ margin: '12px 12px 0px 12px' }} />
                                 <div className="resourceContent">统计分析ozon等平台的销售数据<br /><br />助您精准选品，早日爆单。<br /><br />超高性价比，降低资金成本。</div>
                                 <div className="textPrice">￥18 <span className="textOriginalPrice" style={{ position: 'absolute' }}>￥36</span></div>
                                 <Button type="primary" className="cardBtn" onClick={() => this.setStateKeyValue("showModal_analysis_data", true)}>立即购买</Button>
                              </Card>

                              {/* <Card hoverable className="cardItem" cover={<img alt="example" src="/img/follow_fans.png" style={{ borderRadius: '0px' }} />} bodyStyle={{ padding: '0px' }} >
                              <Card.Meta title="关注大卖粉丝" description="粉丝回关后，您新上架的商品将会推送给粉丝。" style={{ margin: '12px 12px 0px 12px', minHeight: '99px' }} />
                              <div className="textPrice">￥5</div>
                              <Button type="primary" className="cardBtn" onClick={() => this.setStateKeyValue("showModal_follow_fans", true)}>立即购买</Button>
                           </Card> */}
                           </>
                        },
                        // {
                        //    label: '功能权限',
                        //    key: "3",
                        //    children: <>
                        //    </>
                        // },
                        // {
                        //    label: '订购说明',
                        //    key: "4",
                        //    children: <></>
                        // },

                     ]}
                  />





               </div>


            </div>
         </React.Fragment>
      );
   }
}

export default Price;