// ##############################
// // // javascript library for creating charts
// #############################
// import echart from '../img/bigScreenImg'
import CommonUtils from '../../http/Common.js';

var echarts = require("echarts");


//echarts在线文档： https://echarts.apache.org/examples/zh/index.html

// ##############################
// // // Charts used in Dahsboard view
// #############################

// ##############################
// // // variables used to create animation on charts
// #############################

var delays = 80,
    durations = 500;
var delays2 = 80,
    durations2 = 500;



export const heatMapChart = {
    title: {
        show: false
    },
    grid: {
        top: 10,
        bottom: 40,
        right: 0
    },
    tooltip: {
        trigger: 'axis',
        backgroundColor: '#fff',
        textStyle: {
            color: '#000'
        },
        // formatter: "{a} <br/>{b}: {c} ({d}%)",
        //  formatter: function(params) {
        //       var result = "地区: "+params[0].name+"</br>";
        //       let allCount = 0;
        //       let index = 0;
        //       params.forEach(function (item) {
        //           if(item.seriesName == "定位成功率"){
        //               result += item.marker + " " + item.seriesName + " : " + item.value +" %</br>";
        //           }else{
        //               allCount += item.value;
        //               index ++;
        //               result += item.marker + " " + item.seriesName + " : " + item.value +"</br>";
        //               if(index==2){
        //                 result += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#fd9812;"></span>'
        //                  result += " " + "定位总数" + " : " + allCount +"</br>";
        //               }

        //           }
        //       });
        //       return result;
        //   }
    },
    //color:['#ff0','#f00'],
    legend: {
        show: false,
        data: ['定位总数', '定位成功率']
    },
    toolbox: {
        show: false
    },
    dataZoom: {
        show: false,
        start: 0,
        end: 100
    },
    xAxis:
    {

        type: 'category',
        boundaryGap: true,
        axisTick: {
            show: false
        },
        axisLine: {
            lineStyle: {
                color: '#ddd', // 颜色
                width: 0.4 // 粗细
            }
        },
        axisLabel: {
            interval: 0,
            rotate: 30,//倾斜度
            textStyle: {
                fontSize: 14,
                color: '#fff'
            },
            lineStyle: {
                color: "#cccccc",
                width: 2,
                type: "dotted"
            },

        },
        data: []
    },

    yAxis: [
        {
            type: 'value',
            scale: true,
            //max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#e6e6e6",
                    width: 2,
                    type: 'dotted'
                }
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10,
                    color: '#fff'
                }
            }
        }
    ],
    series: [
        {
            name: '定位总数',
            type: 'bar',
            barMaxWidth: 25,
            yAxisIndex: 0,
            stack: 'number',
            itemStyle: {
                // opacity:0.5,
                // color:'#50aa54'
                color: '#00bcd4'
            },
            data: []/*(function (){
                var res = [];
                var len = 12;
                while (len--) {
                    res.push(Math.random() * 50);
                }
                return res;
            })()*/
        }
    ]
};

//首页的char


//统计的char
export const statisticsChart = {
    // title: {
    //     show: false
    // },
    title: {
        show:false,
        text: '类目销售统计数据',
        // subtext: '销售额'
    },
    grid: {
        top: '20%',
        // bottom:10,
        right: '10%',
        left: '5%'
    },
    tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        textStyle: {
            color: '#000',
        },
        // width: '50px',
        extraCssText: 'box-shadow:0px 15px 30px 0px rgba(0, 0, 0, 0.14);box-radius: 6px;padding:10px',
        // borderRadius: '6px',
        // extraCssText: 'box-radius: 6px;',
        // "时间: " + params[0].name + "</br>";
        formatter: function (params) {
            // console.log("params===>",params)
            var result = '<span style="display:inline-block;color:#6F7390;font-size:14px;font-weight: bold">' + "时间: " + params[0].name + '</span>' + "</br>"
            params.forEach(function (item) {
                if (item.seriesName.includes("销售额")) {
                    // var showValue = item.value > 10000 ? CommonUtils.roundToDecimalPlace(item.value / 10000, 2) + ' 万元' : item.value + ' 元'
                    var showValue = CommonUtils.getChineseNumber(item.value) + ' 元'
                    result += item.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + item.seriesName + '</span>' + " : "
                        + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + showValue + '</span>' + " <br />";
                } else if (item.seriesName.includes("销量")) {
                    // var showValue = item.value > 10000 ? CommonUtils.roundToDecimalPlace(item.value / 10000, 2) + ' 万件' : item.value + ' 件'
                    var showValue = CommonUtils.getChineseNumber(item.value) + ' 件'
                    result += item.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + item.seriesName + '</span>' + " : "
                        + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + showValue + '</span>' + " <br />";
                } else if (item.seriesName.includes("点击量")) {
                    // var showValue = item.value > 10000 ? CommonUtils.roundToDecimalPlace(item.value / 10000, 2) + ' 万次' : item.value + ' 次'
                    var showValue = CommonUtils.getChineseNumber(item.value) + ' 次'
                    result += item.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + item.seriesName + '</span>' + " : "
                        + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + showValue + '</span>' + " <br />";
                } else if (item.seriesName.includes("搜索量")) {
                    // var showValue = item.value > 10000 ? CommonUtils.roundToDecimalPlace(item.value / 10000, 2) + ' 万次' : item.value + ' 次'
                    var showValue = CommonUtils.getChineseNumber(item.value) + ' 次'
                    result += item.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + item.seriesName + '</span>' + " : "
                        + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + showValue + '</span>' + " <br />";
                } else {
                    result += item.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + item.seriesName + '</span>' + " : "
                        + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + item.value + '</span>' + " <br />";
                }
            });
            return result;
        }
    },
    color: ['#085EEB', '#0AD2FF', '#00BABA', '#6B74FF'],
    legend: {
        show: true,
        bottom: '0%',
        // selected: {
        //     // 选中'系列1'
        //     '销量': true,
        //     // 不选中'系列2'
        //     '销售额': false,
        //     '点击量': false,
        //     '搜索量': false,
        // },
        data: [
            { name: '销量', icon: 'circle', },
            '销售额', "点击量", "搜索量",
        ],
    },
    toolbox: {
        show: false
    },
    dataZoom: {
        show: false,
        start: 0,
        end: 100
    },
    xAxis: {
        type: 'category',
        boundaryGap: true,
        axisTick: {
            show: false
        },
        axisLine: {
            lineStyle: {
                color: '#ddd', // 颜色
                width: 0.4 // 粗细
            }
        },
        axisLabel: {
            textStyle: {
                fontSize: 10,
                color: '#AAAECE'
            },
            lineStyle: {
                color: "#cccccc",
                width: 2,
                type: "dotted"
            },
        },
        data: []
    },

    yAxis: [
        {
            name: '销售额(人民币)',
            type: 'value',
            scale: true,
            //max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            axisTick: {
                show: false,
            },

            splitLine: {
                show: false,
                lineStyle: {
                    color: "#e6e6e6",
                    width: 2,
                    type: 'dotted'
                }
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10,
                    color: '#AAAECE'
                },
            }
        },
        {
            name: '销量',
            type: 'value',
            scale: true,
            //max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#e6e6e6",
                    width: 2,
                    type: 'dotted'
                }
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10,
                    color: '#AAAECE'
                }
            }
        },
        {
            show: false,
            type: 'value',
            scale: true,
            //max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            axisTick: {
                show: false,
            },

            splitLine: {
                show: false,
                lineStyle: {
                    color: "#e6e6e6",
                    width: 2,
                    type: 'dotted'
                }
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10,
                    color: '#AAAECE'
                }
            }
        },
        {
            show: false,
            type: 'value',
            scale: true,
            //max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            axisTick: {
                show: false,
            },

            splitLine: {
                show: false,
                lineStyle: {
                    color: "#e6e6e6",
                    width: 2,
                    type: 'dotted'
                }
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10,
                    color: '#AAAECE'
                }
            }
        },

    ],
    series: [
        {
            name: '销量',
            type: 'bar',
            barMaxWidth: 13,
            smooth: false,//曲线
            yAxisIndex: 1,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []
        },
        {
            name: '销售额',
            type: 'bar',
            barMaxWidth: 13,
            smooth: false,//曲线
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []
        },
        {
            name: '点击量',
            type: 'bar',
            barMaxWidth: 13,
            smooth: false,//曲线
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []
        },
        {
            name: '搜索量',
            type: 'bar',
            barMaxWidth: 13,
            smooth: false,//曲线
            yAxisIndex: 3,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []
        },

        // {
        //     name: '增长率',
        //     type: 'line',
        //     smooth: false,//曲线
        //     yAxisIndex: 2,
        //     itemStyle: {
        //         normal: {
        //             //opacity:0.5,
        //             // color: '#009688',
        //             lineStyle: {
        //                 width: 3,
        //                 // color: '#009688',
        //                 // opacity:0.7, 
        //             }
        //         }
        //     },
        //     data: [],
        // },
    ]
};

//统计的饼图的chart
export const statisticsPieChart = {
    legend: {
        show: false,
    },
    tooltip: {
        trigger: 'item',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        textStyle: {
            color: '#000',
        },
        extraCssText: 'box-shadow:0px 15px 30px 0px rgba(0, 0, 0, 0.14);box-radius: 6px;padding:10px',
        formatter: function (params) {
            var result = '<span style="display:inline-block;color:#6F7390;font-size:14px;font-weight: bold">' + params.seriesName + '(月度)</span>' + "</br>"
            var showValue = params.value > 10000 ? CommonUtils.roundToDecimalPlace(params.value / 10000, 2) + ' 万元' : params.value + ' 元'
            result += params.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + params.name + '</span>' + " : "
                + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + showValue + '</span>' + " <br />";

            return result;
        },

    },

    // color: ['#085EEB', '#0AD2FF', '#00BABA', '#6B74FF'],
    // color: ['#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed',
    //     '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0',
    //     '#1e90ff', '#ff6347', '#7b68ee', '#00fa9a', '#ffd700',
    //     '#6b8e23', '#ff00ff', '#3cb371', '#b8860b', '#30e0e0'],
    // color: ['#e74a9a', '#fa5a59',  '#fb8749',  '#f7bc5f',  '#faef4e',  '#d3ee5c',  '#b1ef15',  '#83e740',  '#36df2d',  '#1fe7ad',  '#30dae5',  '#33a7e2', '#7386f3',  '#467ae6',  '#3e5aec',  '#8050eb',  '#7f50ed' ,],
    // color: [  '#83e740',  '#36df2d',  '#1fe7ad',  '#30dae5',  '#33a7e2', '#7386f3',  '#467ae6',  '#3e5aec',  '#8050eb',  '#7f50ed','#e74a9a', '#fa5a59',  '#fb8749',  '#f7bc5f',  '#faef4e',  '#d3ee5c',  '#b1ef15', ],
    color: ['#30dae5',  '#33a7e2', '#7386f3',  '#467ae6',  '#3e5aec',  '#8050eb',  '#7f50ed','#e74a9a', '#fa5a59',  '#fb8749',  '#f7bc5f',  '#faef4e',  '#d3ee5c',  '#b1ef15',  '#83e740',  '#36df2d',  '#1fe7ad',   ],
    series: [
        {
            name: '销量',
            type: 'pie',
            // radius: ['40%', '70%'],
            radius: ['30%', '60%'], // 设置内半径和外半径，支持像素和百分比
            avoidLabelOverlap: false,
            data: []
        }
    ],
    graphic: {
        type: 'text',
        left: 'center',
        top: 'center',
        style: {
            text: '销量', // 要显示的文字
            textAlign: 'center',
            fill: '#000', // 文字颜色
            fontSize: 20
        }
    }
}



// 内网统计的char
export const statisticsChartInner = {
    title: {
        show: false
    },
    grid: {
        top: '10%',
        // bottom:10,
        right: '10%',
        left: '5%'
    },
    tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        textStyle: {
            color: '#000',
        },
        // width: '50px',
        extraCssText: 'box-shadow:0px 15px 30px 0px rgba(0, 0, 0, 0.14);box-radius: 6px;padding:10px',
        // borderRadius: '6px',
        // extraCssText: 'box-radius: 6px;',
        // "时间: " + params[0].name + "</br>";
        formatter: function (params) {
            var result = '<span style="display:inline-block;color:#6F7390;font-size:14px;font-weight: bold">' + "时间: " + params[0].name + '</span>' + "</br>"

            params.forEach(function (item) {
                if (item.seriesName.includes("率")) {
                    result +=
                        // item.marker + " " + item.seriesName + " : " + item.value + " %</br>";

                        item.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + item.seriesName + '</span>' + " : " + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + item.value + '%' + '</span>' + " <br />";


                } else if (item.seriesName == "呼叫量") {
                    result +=
                        // item.marker + " " + item.seriesName + " : " + item.value + " 次</br>";
                        item.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + item.seriesName + '</span>' + " : " + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + item.value + '次' + '</span>' + " <br />";


                } else {
                    result +=
                        // item.marker + " " + item.seriesName + " : " + item.value + "</br>";
                        item.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + item.seriesName + '</span>' + " : " + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + item.value + '</span>' + " <br />";
                }
            });
            return result;
        }
    },
    color: ['#085EEB', '#0AD2FF', '#00BABA', '#6B74FF'],
    legend: {
        show: true,
        // data: ['总定位量', '移动', '联通', '电信', '总成功率', '移动成功率', '联通成功率', '电信成功率',],
        bottom: '0%',
        selected: {},
        data: [
            {
                name: '总定位量',
                icon: 'circle',
            },


            {
                name: '移动',
                icon: 'circle',
            },
            {
                name: '联通',
                icon: 'circle',
            },
            {
                name: '电信',
                icon: 'circle',
            },
            // {
            //     name: '总成功率',
            //     icon: "image://" + require("/"),
            // },



            '总成功率',





            '移动成功率',
            '联通成功率',
            '电信成功率',
        ],
    },
    toolbox: {
        show: false
    },
    dataZoom: {
        show: false,
        start: 0,
        end: 100
    },
    xAxis:
    {

        type: 'category',
        boundaryGap: true,
        axisTick: {
            show: false
        },
        axisLine: {
            lineStyle: {
                color: '#ddd', // 颜色
                width: 0.4 // 粗细
            }
        },
        axisLabel: {
            textStyle: {
                fontSize: 10,
                color: '#AAAECE'
            },
            lineStyle: {
                color: "#cccccc",
                width: 2,
                type: "dotted"
            },
        },


        data: (function () {
            var res = [];
            var nowTime = new Date();
            nowTime.setMilliseconds(0);
            nowTime.setSeconds(0);
            nowTime.setMinutes(0);

            for (var i = 0; i < 12; i++) {
                res.unshift((nowTime + "").substring(16, 21));
                nowTime.setHours(nowTime.getHours() - 1);
            }
            return res;
        })(),
    },

    yAxis: [
        {
            type: 'value',
            scale: true,
            //max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#e6e6e6",
                    width: 2,
                    type: 'dotted'
                }
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10,
                    color: '#AAAECE'
                }
            }
        },
        {
            show: false,
            type: 'value',
            scale: true,
            //max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#e6e6e6",
                    width: 2,
                    type: 'dotted'
                }
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10,
                    color: '#AAAECE'
                }
            }
        },
        {
            show: true,
            type: 'value',
            scale: true,
            max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: true,
                interval: 'auto',
                textStyle: {
                    color: '#AAAECE'
                },
                formatter: '{value} %',
                min: 0,
                max: 100,
            }
        }
    ],
    series: [
        {
            name: '总定位量',
            type: 'bar',
            barMaxWidth: 20,
            smooth: false,//曲线
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []
        },
        {
            name: '移动',
            type: 'bar',
            barMaxWidth: 20,
            smooth: false,//曲线
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []
        },
        {
            name: '联通',
            type: 'bar',
            barMaxWidth: 20,
            smooth: false,//曲线
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []

        },
        {
            name: '电信',
            type: 'bar',
            barMaxWidth: 20,
            smooth: false,//曲线
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []

        },

        {
            name: '总成功率',
            type: 'line',
            smooth: false,//曲线
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    //opacity:0.5,
                    // color: '#009688',
                    lineStyle: {
                        width: 3,
                        // color: '#009688',
                        // opacity:0.7, 
                    }
                }
            },
            data: [],
        },
        {
            name: '移动成功率',
            type: 'line',
            smooth: false,//曲线
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    //opacity:0.5,
                    // color: '#009688',
                    lineStyle: {
                        width: 3,
                        // color: '#009688',
                        // opacity:0.7, 
                    }
                }
            },
            data: [],
        },
        {
            name: '联通成功率',
            type: 'line',
            smooth: false,//曲线
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    //opacity:0.5,
                    // color: '#009688',
                    lineStyle: {
                        width: 3,
                        // color: '#009688',
                        // opacity:0.7, 
                    }
                }
            },
            data: [],
        },
        {
            name: '电信成功率',
            type: 'line',
            smooth: false,//曲线
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    //opacity:0.5,
                    // color: '#009688',
                    lineStyle: {
                        width: 3,
                        // color: '#009688',
                        // opacity:0.7, 
                    }
                }
            },
            data: [],
        },
    ]
};




export const usedRateChart = {

    tooltip: {
        trigger: 'item',
        formatter: function (params) {
            var result = params.data.name + "</br>";
            if (params.data) {
                var value = params.data.value || 0;
                var usedCount = params.data.usedCount || 0;
                var usedPercentage = params.data.usedPercentage || 0;
                var count = params.data.count || 0;
                result += params.marker + " 今日定位量 : " + count + " 次 </br>";
                result += params.marker + " 今日使用量 : " + usedCount + " 次 </br>";
                result += params.marker + " 今日使用率 : " + usedPercentage + " % </br>";
            }

            return result;
        }
    },
    grid: {
        left: '10%',
        right: '10%',
        top: '10%',
        bottom: '10%',
        containLabel: true
    },
    geo: {
        map: 'geoJson',
        roam: false,
        zoom: 1.2,
        tooltip: {
            show: true,       //不显示提示标签
        },
        label: {
            normal: {
                show: true,//显示省份标签
                textStyle: { color: "#c71585" }//省份标签字体颜色
            },
            emphasis: {//对应的鼠标悬浮效果
                show: true,
                textStyle: { color: "#fffff" }
            }
        },
        itemStyle: {
            normal: {
                borderWidth: .5,//区域边框宽度
                borderColor: '#fff',//区域边框颜色
                areaColor: "#ffefd5",//区域颜色
                label: { show: false }
            },
            emphasis: {
                show: false,
                borderWidth: .5,
                borderColor: '#4b0082',
                areaColor: "#ffdead",
            }
        },
    },
    visualMap: {
        //   show:false,
        min: 0,
        max: 1000,
        text: ['High', 'Low'],
        right: 50,
        top: 'center',
        realtime: false,
        calculable: true,
        inRange: {
            color: ['#ddfdfd', '#6cc8c1', '#029588']  //var mycolors = ['#ddfdfd','#bdefec','#6cc8c1','#4cbab0','#0d9c8f','#029588'];
        }
    },
    series: [
        {
            name: "定位使用量",
            type: 'map',
            mapType: 'geoJson', // 自定义扩展图表类型
            roam: false,
            zoom: 1.2,
            tooltip: {
                show: true,       //不显示提示标签
            },
            label: {
                normal: {
                    show: true    //显示省份标签
                },
                emphasis: {
                    show: true,
                }
            },
            itemStyle: {
                normal: {
                    borderWidth: .5,      //区域边框宽度
                    borderColor: '#fff',  //区域边框颜色
                    label: { show: true }
                },
                emphasis: {
                    show: true,
                }
            },
            data: []
        }
    ],
}

export const mapHomePageChart = {

    tooltip: {
        trigger: 'item',
        formatter: function (params) {
            let value = params.data.value ? params.data.value : '-'
            var result = '<span style="display:inline-block;color:#6F7390;font-size:14px;font-weight: 500">' + params.data.name + ' : ' + '</span>' + '<span style="display:inline-block;color:#383D5C;font-size:20px;font-weight:bold">' + '&nbsp;' + value + '</span>'
            return result;
        },
        backgroundColor: '#fff',
        textStyle: { color: '#000' }
    },
    grid: {
        left: '10%',
        right: '10%',
        top: '10%',
        bottom: '10%',
        containLabel: true
    },
    geo: {
        map: 'geoJson',
        roam: false,
        zoom: 1.2,
        tooltip: {
            show: true,       //不显示提示标签
        },
        label: {
            normal: {
                show: true,//显示省份标签
                textStyle: { color: "#828AA7" }//省份标签字体颜色
            },
            emphasis: {//对应的鼠标悬浮效果
                show: true,
                textStyle: { color: "#fffff" }
            }
        },
        itemStyle: {
            normal: {
                borderWidth: .5,//区域边框宽度
                borderColor: '#fff',//区域边框颜色
                areaColor: "#ffefd5",//区域颜色
                label: { show: false }
            },
            emphasis: {
                show: true,
                borderWidth: .5,
                borderColor: '#4b0082',
                areaColor: "#ffdead",
            },
        },
    },
    visualMap: {
        show: false,
        min: 0,
        max: 1000,
        text: ['High', 'Low'],
        realtime: false,
        calculable: true,
        inRange: {
            color: ['#ddfdfd', '#6cc8c1', '#029588']  //var mycolors = ['#ddfdfd','#bdefec','#6cc8c1','#4cbab0','#0d9c8f','#029588'];
        }
    },
    series: [
        {
            //   name:"定位使用量",
            name: "定位量",
            type: 'map',
            mapType: 'geoJson', // 自定义扩展图表类型
            roam: false,
            zoom: 1.2,
            tooltip: {
                show: true,       //不显示提示标签
            },
            label: {
                normal: {
                    show: true    //显示省份标签
                },
                emphasis: {
                    show: true,
                }
            },
            itemStyle: {
                normal: {
                    borderWidth: .5,      //区域边框宽度
                    borderColor: '#BFC6E0',  //区域边框颜色
                    label: { show: true },
                },
                emphasis: {
                    show: true,
                    // areaColor: '#7394FF', 
                    areaColor: {
                        type: 'linear-gradient',
                        // x: 0,
                        // y: 1000,
                        // x2: 0,
                        // y2: 0,
                        colorStops: [
                            {
                                offset: 0.1,
                                color: '#7394FF' // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: '#3F5BFE' // 100% 处的颜色
                            }
                        ],
                        // global: true // 缺省为 false
                    },
                    label: {
                        color: '#fff',
                        fontSize: 14,
                        fontWeight: 500
                    }
                },
            },
            data: []
        }
    ],
}

// emphasis: {
//     borderWidth: 3,
//     itemStyle: {
//       // color: '#ffffff',
//       // textStyle: {
//       //   color: '#fff',
//       //   fontSize: 12,
//       //   backgroundColor: 'transparent'
//       // },
//       // opacity: 0.75
//     },
//     textStyle: {
//       color: '#fff',
//       fontSize: 12,
//       backgroundColor: 'transparent'
//     },
//     areaColor: {
//       type: 'linear-gradient',
//       x: 0,
//       y: 1000,
//       x2: 0,
//       y2: 0,
//       colorStops: [
//         {
//           offset: 0.5,
//           color: '#0D59C1' // 0% 处的颜色
//         },
//         {
//           offset: 1,
//           color: '#53C9C7' // 100% 处的颜色
//         }
//       ],
//       global: true // 缺省为 false
//     }
//   }

// },


// ##############################
// // // Pie Chart
// #############################

export const pieChart = {
    data: {
        labels: ["62%", "32%", "6%"],
        series: [62, 32, 6]
    },
    options: {
        height: "230px"
    }
};


//统计的char
export const bigScreen = {
    title: {
        show: false
    },
    grid: {
        top: 30,
        bottom: 30,
        right: 60,
        left: 60
    },
    tooltip: {
        trigger: 'axis',
        backgroundColor: '#fff',
        textStyle: {
            color: '#000'
        },
        formatter: function (params) {
            var result = "时间: " + params[0].name + "</br>";
            params.forEach(function (item) {
                if (item.seriesName.includes("率")) {
                    result += item.marker + " " + item.seriesName + " : " + item.value + " %</br>";
                } else if (item.seriesName == "呼叫量") {
                    result += item.marker + " " + item.seriesName + " : " + item.value + " 次</br>";
                } else {
                    result += item.marker + " " + item.seriesName + " : " + item.value + "</br>";
                }
            });
            return result;
        }
    },
    color: ['#4C47FF', '#00E9A4', '#36B4FF', '#E9A200'],
    legend: {
        show: true,
        data: [
            {
                name: '总定位量',
                icon: 'circle',
            },
            {
                name: '移动',
                icon: 'circle',
            },
            {
                name: '联通',
                icon: 'circle',
            },
            {
                name: '电信',
                icon: 'circle',
            },
            // {
            //     name: '总成功率',
            //     icon: "",
            // },
            '总成功率',
            '移动成功率',
            '联通成功率',
            '电信成功率',
        ],
        top: 0,
        selected: {},
        textStyle: {//图例文字的样式
            color: '#ACB0FF',
        },
        x: 'center',
        padding: [0, 0, 100, 0],
        selectedMode: false,
        // icon: 'circle',


    },
    toolbox: {
        show: false
    },
    dataZoom: {
        show: false,
        start: 0,
        end: 100
    },
    xAxis:
    {

        type: 'category',
        boundaryGap: true,
        axisTick: {
            show: false
        },
        axisLine: {
            lineStyle: {
                color: '#ddd', // 颜色
                width: 0.4 // 粗细
            }
        },
        axisLabel: {
            textStyle: {
                fontSize: 10,
                color: '#ACB0FF'
            },
            lineStyle: {
                color: "#cccccc",
                width: 2,
                type: "dotted"
            },
        },
        data: (function () {
            var res = [];
            var nowTime = new Date();
            nowTime.setMilliseconds(0);
            nowTime.setSeconds(0);
            nowTime.setMinutes(0);

            for (var i = 0; i < 12; i++) {
                res.unshift((nowTime + "").substring(16, 21));
                nowTime.setHours(nowTime.getHours() - 1);
            }
            return res;
        })(),
    },
    yAxis: [
        {
            type: 'value',
            scale: true,
            //max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#e6e6e6",
                    width: 2,
                    type: 'dotted'
                }
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10,
                    color: '#ACB0FF'
                }
            }
        },
        {
            show: false,
            type: 'value',
            scale: true,
            //max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: "#e6e6e6",
                    width: 2,
                    type: 'dotted'
                }
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10,
                    color: '#ACB0FF'
                }
            }
        },
        {
            show: true,
            type: 'value',
            scale: true,
            max: 100,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
                show: false
            },
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: true,
                interval: 'auto',
                textStyle: {
                    color: '#ACB0FF'
                },
                formatter: '{value} %',
                min: 0,
                max: 100,
            }
        }
    ],
    series: [
        {
            name: '总定位量',
            type: 'bar',
            barMaxWidth: 20,
            smooth: false,//曲线
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []
        },
        {
            name: '移动',
            type: 'bar',
            barMaxWidth: 20,
            smooth: false,//曲线
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []
        },
        {
            name: '联通',
            type: 'bar',
            barMaxWidth: 20,
            smooth: false,//曲线
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []

        },
        {
            name: '电信',
            type: 'bar',
            barMaxWidth: 20,
            smooth: false,//曲线
            yAxisIndex: 0,
            itemStyle: {
                normal: {
                    // color: '#1e9fff',
                    lineStyle: {
                        width: 3,
                        // color: '#1e9fff',
                    }
                }
            },
            data: []

        },

        {
            name: '总成功率',
            type: 'line',
            smooth: false,//曲线
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    //opacity:0.5,
                    // color: '#009688',
                    lineStyle: {
                        width: 3,
                        // color: '#009688',
                        // opacity:0.7, 
                    }
                }
            },
            data: [],
            symbolSize: 10,

        },
        {
            name: '移动成功率',
            type: 'line',
            smooth: false,//曲线
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    //opacity:0.5,
                    // color: '#009688',
                    lineStyle: {
                        width: 3,
                        // color: '#009688',
                        // opacity:0.7, 
                    }
                }
            },
            data: [],
            symbolSize: 10,

        },
        {
            name: '联通成功率',
            type: 'line',
            smooth: false,//曲线
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    //opacity:0.5,
                    // color: '#009688',
                    lineStyle: {
                        width: 3,
                        // color: '#009688',
                        // opacity:0.7, 
                    }
                }
            },
            data: [],
            symbolSize: 10,

        },
        {
            name: '电信成功率',
            type: 'line',
            smooth: false,//曲线
            yAxisIndex: 2,
            itemStyle: {
                normal: {
                    //opacity:0.5,
                    // color: '#009688',
                    lineStyle: {
                        width: 3,
                        // color: '#009688',
                        // opacity:0.7, 
                    }
                }
            },
            data: [],
            symbolSize: 10,
        },
    ]
};

export const bigScreenBar = {
    // color: ['#4C47FF', '#00E9A4'],
    legend: {
        // selectedMode: false,
        show: false,
        icon: 'circle',
    },

    polar: {
        radius: ['80%', '60%'],
        // radius: '80%',

        // center: ['50%', '50%']
    },
    // xAxis: {
    //     axisTick: {
    //         lineStyle: {
    //             // color: '#000000',
    //         },
    //     },
    // },
    // 极坐标角度轴
    angleAxis: {
        min: 0,
        max: 100,
        // startAngle: 0,
        // endAngle: -0,
        clockwise: true,
        show: false, // 隐藏刻度线
    },
    // 极坐标径向轴
    radiusAxis: {
        type: 'category',
        // 隐藏极坐标轴线
        axisLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
    },
    // tooltip: {
    //     show: true,
    //     formatter: (val) => {
    //         return `${val.seriesName}: ${val.data}%`;
    //     },
    //     backgroundColor: 'rgba(31, 196, 225, 0.2)',
    //     borderColor: 'rgba(31, 196, 225, 0.6)',
    // },
    series: [
        {
            // 进度条
            type: 'bar',
            coordinateSystem: 'polar',
            // 设置柱子背景灰色，需开启showBackground才能显示backgroundStyle
            showBackground: true,
            backgroundStyle: {
                // color: 'rgba(14, 61, 128,0.3)',
                // color: 'rgba(255,255,255, 0.15)',
            },
            // 两端设置圆角
            roundCap: true,
            barWidth: 5, //柱图宽度
            itemStyle: {
                color: {
                    x: 0,
                    y: 0,
                    x1: 0,
                    y1: 1,
                    colorStops: [
                        {
                            offset: 0.5,
                            color: '#4B60DC',
                            opacity: '0.1',
                        },
                        {
                            offset: 1,
                            color: '#15216F',
                        },
                    ],
                },
            },
            data: ['100'],
        },
        {
            // 刻度线设置
            type: 'gauge',
            name: '',
            radius: '67%',
            center: ['50%', '50%'],
            startAngle: 0,
            endAngle: 360,
            silent: false,
            splitNumber: 50,
            splitLine: {
                length: 6,
                distance: 30,
                lineStyle: {
                    width: 3,
                    color: '#3E54C9',
                },
            },
            // color: '#3E54C9',
            // 是否显示仪表盘数据

            detail: {
                show: false,
            },
            pointer: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            axisLine: {
                show: false,
            },
            z: 10,
            // axisLine: {
            //     show: true, // 是否显示仪表盘轴线(轮廓线),默认true
            //     lineStyle: {
            //         color: [
            //             [0.2, '#86B379'],
            //             [0.8, '#68A54A'],
            //             [1, '#408829'],
            //         ],
            //         width: 8,
            //     },
            // },
        },
        {
            color: ['#00E9A4', '#4C47FF'],
            type: 'pie',
            radius: ['40%', '60%'],
            hoverAnimation: false,
            avoidLabelOverlap: false,
            // label: {
            //     show: false,
            //     position: 'center'
            // },
            // center: ['40%', '60%'],
            itemStyle: {
                borderRadius: 10,
                borderColor: '#000',
                borderWidth: 2
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '40',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            label: {
                normal: {
                    show: true,
                    position: 'center',
                    color: '#fff',
                    formatter: '',
                    fontSize: 24
                },
                emphasis: {//中间文字显示
                    show: true,
                }
            },
            lableLine: {
                normal: {
                    show: false
                },
                emphasis: {
                    show: true
                },
                tooltip: {
                    show: false
                }
            },




            data: [

            ],
        },
    ],
}



export const homePie = {
    title: {
        text: '',
        left: 'center',
        textStyle: {
            width: '32px',
            height: '22px',
            // fontSize: '16px',
            fontWeight: 600,
            color: '#383D5C',
        },
        top: '5%'
    },
    tooltip: {
        show: false,
        trigger: 'item'
    },
    legend: {
        show: false,
        selectedMode: false
    },
    series: [
        {
            // name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            center: ['50%', '60%'],
            emphasis: {
                label: {
                    show: false,
                    fontSize: '40',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            itemStyle: {
                normal: {
                    color: function (params) {
                        var colorList = [
                            {
                                c1: ' #6183FF', //实践
                                c2: '#324CFD'
                            },
                            {
                                c1: ' #45DBFF', //管理
                                c2: '#21B5FF'
                            },

                        ];
                        return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                            {
                                offset: 0,
                                color: colorList[params.dataIndex].c1
                            },
                            {
                                offset: 1,
                                color: colorList[params.dataIndex].c2
                            }
                        ]);
                    }
                }
            },
            hoverAnimation: false,

            data: []
        }
    ]
}

export const statisticsPie = {
    color: ['#6B74FF', '#0AD2FF', '#085EEB'],
    title: {
        // text: 'Referer of a Website',
        // subtext: 'Fake Data',
        right: 'center'
    },
    tooltip: {
        trigger: 'item',
        extraCssText: 'box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.14);',
        extraCssText: 'box-radius: 6px',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        textStyle: {
            color: '#000'
        },
        formatter: function (params) {
            var result = params.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + params.name + '</span>' + " : " + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + params.value + '</span>';
            return result;
        }
    },
    legend: {
        orient: 'vertical',
        right: '8%',
        top: 'center',
        icon: 'circle',
        // padding:'5'
        itemGap: 25,
        textStyle: {
            color: '#6F7390'
        },
        selectedMode: false,
    },
    series: [
        {
            type: 'pie',
            radius: '50%',
            data: [
                // { value: 10, name: '定位耗时0-5s' },
                // { value: 20, name: '定位耗时5-10s' },
                // { value: 30, name: '定位耗时10s以上' }
            ],
            itemStyle: {
                borderRadius: 1,
                borderColor: '#fff',
                borderWidth: 1
            },
            label: {
                //单独显示该数据项
                show: true,
                formatter: '{d}%',
                position: 'outer',
                fontSize: 15,
                textStyle: {
                    color: '#6F7390'
                }
            },
            labelLine: {
                normal: {
                    show: false
                }
            }
        }
    ]
}


export const statisticsPieT = {
    color: ['#6B74FF', '#0AD2FF', '#085EEB', '#00BABA', '#F69D4F'],
    title: {
        // text: 'Referer of a Website',
        // subtext: 'Fake Data',
        right: 'center'
    },
    tooltip: {
        trigger: 'item',
        extraCssText: 'box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.14);',
        extraCssText: 'box-radius: 6px',
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        textStyle: {
            color: '#000'
        },
        formatter: function (params) {
            var result = params.marker + '<span style="display:inline-block;color:#6F7390;font-size:12px">' + params.name + '</span>' + " : " + '<span style="display:inline-block;color:#383D5C;font-size:12px;font-weight: bold">' + params.value + '</span>';
            return result;
        }
    },
    legend: {
        orient: 'vertical',
        right: '9%',
        top: 'center',
        icon: 'circle',
        // padding:'5'
        itemGap: 25,
        textStyle: {
            color: '#6F7390'
        },
        selectedMode: false,
    },
    series: [
        {
            type: 'pie',
            radius: '50%',
            data: [
                // { value: 10, name: '移动账号无效' },
                // { value: 20, name: '主叫停机或关机' },
                // { value: 40, name: '运营商系统错误' },
                // { value: 50, name: '基站数据缺失' },
                // { value: 60, name: '其他' }
            ],
            itemStyle: {
                borderRadius: 1,
                borderColor: '#fff',
                borderWidth: 1
            },
            label: {
                //单独显示该数据项
                show: true,
                formatter: '{d}%',
                position: 'outer',
                fontSize: 15,
                textStyle: {
                    color: '#6F7390'
                }
            },
            labelLine: {
                normal: {
                    show: false
                }
            }
        }
    ]
}


// module.exports = {
//     heatMapChart,
//     pieChart,
//     mapHomePageChart,
//     statisticsChart,
//     usedRateChart,
//     bigScreen,
//     bigScreenBar,
//     homePie,
//     statisticsPie,
//     statisticsPieT
// };
