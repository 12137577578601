import './index.css'
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';

import Homepage from "./pages/Homepage.js";
import Product from "./pages/Product.js";
import Price from "./pages/Price.js";
import Download from "./pages/Download.js";
import Test from "./pages/Test.js";
import Feedback from "./pages/Feedback.js";
import Help from "./pages/Help.js";
import Profile from "./pages/Profile.js";
import Discount from "./pages/Discount.js";
import Agreement from "./pages/Agreement.js";
import Setting from "./pages/Setting.js";
import UserPage from "./pages/UserPage.js";
import AgentPage from "./pages/AgentPage.js";
import AgentRecommendPage from "./pages/AgentRecommendPage.js";
import AgentInvitePage from "./pages/AgentInvitePage.js";
import AgentExchangePage from "./pages/AgentExchangePage.js";
import AgentChangePage from "./pages/AgentChangePage.js";
import AgentCashOutPage from "./pages/AgentCashOutPage.js";
import AgentCheckPage from "./pages/AgentCheckPage.js";
import UserManagerPage from "./pages/UserManagerPage.js";
import CouponManagerPage from "./pages/CouponManagerPage.js";
import ApiPage from "./pages/ApiPage.js";
import CashOutCheckPage from "./pages/CashOutCheckPage.js";
import CouponPage from "./pages/CouponPage.js";
import CouponNoUsePage from "./pages/CouponNoUsePage.js";
import CouponUsedPage from "./pages/CouponUsedPage.js";
import CouponExpirePage from "./pages/CouponExpirePage.js";
import ShopAuthShopee from "./pages/ShopAuthShopee.js";
import ShopResendCode from "./pages/ShopResendCode.js";
import ShopAuthOzon from "./pages/ShopAuthOzon.js";
import ShopAuthTiktok from "./pages/ShopAuthTiktok.js";
import OpenShopPage from "./pages/OpenShopPage.js";
import XpathSetting from "./pages/XpathSetting.js";
import CreateNewsPage from "./pages/CreateNewsPage.js";
import NewsManagerPage from "./pages/NewsManagerPage.js";
import NewsPage from "./pages/NewsPage.js";
import NewsDetailPage from "./pages/NewsDetailPage.js";
import StatisticsPage from "./pages/StatisticsPage.js";
import OzonHotSalesPage from "./pages/statistics/OzonHotSalesPage.js";
import OzonHotSalesNewPage from "./pages/statistics/OzonHotSalesNewPage.js";
import OzonPotentialPage from "./pages/statistics/OzonPotentialPage.js";
import OzonTrendPage from "./pages/statistics/OzonTrendPage.js";
import OzonStatisticsPage from "./pages/statistics/OzonStatisticsPage.js";
import OzonHotCategoryPage from "./pages/statistics/OzonHotCategoryPage.js";
import OzonPotentialCategoryPage from "./pages/statistics/OzonPotentialCategoryPage.js";


import zhCN from 'antd/locale/zh_CN';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {/* BrowserRouter 可以使用this.props.history.push('/price');//跳转到新的路由 ;  但是HashRouter不行

    CDN配置: 重写回源URL 可以解决跳转问题
    待重写的Path  --->  目标Path --->  执行规则
    ^/static/(.*)$   --->  /static/$1  --->  break
    ^/img/(.*)$  --->  /img/$1  ---> break
    ^/  --->  /index.html  ---> 空
    
    */}
    {/*
        HashRouter在用户刷新时,阿里云cdn可以解析,但是 BrowserRouter的就无法解析

        HashRouter路由的阿里云OSS域名EdgeScript自定义策略:
        if eq($uri, '/') {
            rewrite('/index.html', 'redirect')
      }
  */}
    <Switch>
      {/* 这里的exact={true}必须要加, 不然/login/:token也会匹配到这个url */}
      {/* <Route exact={true} path="/v" component={videoCall} /> */}
      {/* <Route path="/login/:token" component={Homepage} /> */}
      <Route path="/test" component={Test} />
      {/* 静态界面 */}
      {/* <Route path="/staticPage">
        <a href="staticPage.html">Go to static HTML page</a>
      </Route> */}

      <Route path="/" render={() =>
        //--- 兼容旧版浏览器 :  `hashPriority` 默认为 `low`，配置为 `high` 后，会移除 `:where` 选择器封装 ; Ant Design 使用了 CSS 逻辑属性 `transformers` 提供预处理功能将样式进行转换  
        <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
          {/* 全局化设置 */}
          <ConfigProvider locale={zhCN}>
            <App>
              <Switch>
                <Route path='/home' component={Homepage} />
                <Route path='/product' component={Product} />
                <Route path='/price' component={Price} />
                <Route path='/download' component={Download} />
                <Route path='/help' component={Help} />
                <Route path='/agreement' component={Agreement} />
                <Route path='/openShop' component={OpenShopPage} />
                <Route path='/newsList' component={NewsPage} />
                <Route path='/newsDetail' component={NewsDetailPage} />

                {/* 二级路由  UserPage和app中类似   {this.props.children}   StatisticsPage中的path必须有，否则下面的UserPage 的路由也都解析到这里来了。*/}
                <StatisticsPage path='/analysis'>
                  <Switch>
                   
                    <Route path='/analysis/ozon/hotSales' component={OzonHotSalesPage} />
                    <Route path='/analysis/ozon/hotNew' component={OzonHotSalesNewPage} />
                    <Route path='/analysis/ozon/potentialGood' component={OzonPotentialPage} />
                    <Route path='/analysis/ozon/trend' component={OzonTrendPage} />
                    <Route path='/analysis/ozon/statistics' component={OzonStatisticsPage} />
                    <Route path='/analysis/ozon/hotCategory' component={OzonHotCategoryPage} />
                    <Route path='/analysis/ozon/potentialCategory' component={OzonPotentialCategoryPage} />
                    

                    {/* 这个必须放这里否则根目录 /analysis/ozon 没有*/}
                    <Redirect from="/" to="/analysis/ozon/trend" component={OzonTrendPage} />
                  </Switch>

                </StatisticsPage>

                {/* 二级路由  UserPage和app中类似   {this.props.children}*/}
                <UserPage>
                  <Switch>
                    <Route path='/user/profile' component={Profile} />
                    {/* <Route path='/user/agent' component={AgentPage} /> */}
                    <Route path='/user/agentRecommend' component={AgentRecommendPage} />
                    <Route path='/user/agentInvite' component={AgentInvitePage} />
                    <Route path='/user/agentExchange' component={AgentExchangePage} />
                    <Route path='/user/agentChange' component={AgentChangePage} />
                    <Route path='/user/agentCashOut' component={AgentCashOutPage} />
                    {/* <Route path='/user/coupon' component={CouponPage} /> */}
                    <Route path='/user/couponNoUse' component={CouponNoUsePage} />
                    <Route path='/user/couponUsed' component={CouponUsedPage} />
                    <Route path='/user/couponExpire' component={CouponExpirePage} />
                    <Route path='/user/authshopee' component={ShopAuthShopee} />
                    <Route path='/user/authozon' component={ShopAuthOzon} />
                    <Route path='/user/authtiktok' component={ShopAuthTiktok} />
                    <Route path='/user/resendCode' component={ShopResendCode} />
                    <Route path='/agentCheck' component={AgentCheckPage} />
                    <Route path='/userManagerPage' component={UserManagerPage} />
                    <Route path='/couponManagerPage' component={CouponManagerPage} />
                    <Route path='/apiPage' component={ApiPage} />
                    <Route path='/cashOutCheck' component={CashOutCheckPage} />
                    <Route path='/feedback' component={Feedback} />
                    <Route path='/setting' component={Setting} />
                    <Route path='/xpathsetting' component={XpathSetting} />
                    <Route path='/createNews' component={CreateNewsPage} />
                    <Route path='/newsManager' component={NewsManagerPage} />


                    {/* 这个必须放这里否则根目录没有*/}
                    <Redirect from="/" to="/home" component={Homepage} />
                  </Switch>
                </UserPage>



                <Route component={Homepage} />
              </Switch>
            </App>
          </ConfigProvider>

        </StyleProvider>


      } />
    </Switch>
  </BrowserRouter>
);

